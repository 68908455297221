var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var n7,p7,q7,ara,bra,cra,t7,u7,w7,x7,dra,y7,era,fra;n7=function(a){var b=new $CLJS.h(null,3,[$CLJS.KE,$CLJS.p.h($CLJS.UE()),$CLJS.CB,$CLJS.CB.h(a),$CLJS.Hi,$CLJS.KA($CLJS.Hi,$CLJS.CB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zB,b,$CLJS.KA($CLJS.CE,$CLJS.T)(a)],null)};$CLJS.o7=function(a){return $CLJS.E5.h(a)};p7=function(a){return $CLJS.R.j(a,$CLJS.iu,$CLJS.V4)};
q7=function(a,b){return $CLJS.E.g($CLJS.xE.h(a),$CLJS.hm(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OE,$CLJS.wE,$CLJS.NE],null)))};ara=function(a){return $CLJS.B1(a,new $CLJS.h(null,1,[$CLJS.OE,$CLJS.zh],null))};
bra=function(a,b){var c=$CLJS.Re(function(e){return $CLJS.FH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.UA($CLJS.Py);if($CLJS.n($CLJS.TA("metabase.lib.field",c))){var d=$CLJS.tE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a])),$CLJS.Mh.l($CLJS.H([$CLJS.Uk.g($CLJS.WZ,b)]))]));d instanceof Error?$CLJS.SA("metabase.lib.field",c,$CLJS.Lw(),d):$CLJS.SA("metabase.lib.field",
c,$CLJS.Lw.l($CLJS.H([d])),null)}return null};
cra=function(a,b,c){if($CLJS.n(r7))return null;var d=r7;r7=!0;try{var e=$CLJS.m2(a,b),f=$CLJS.n(e)?$CLJS.QW(a,e):$CLJS.QW(a,b),k=function(){var m=$CLJS.s7.h(f);if($CLJS.n(m))return m;m=$CLJS.LA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yV,$CLJS.NW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.ZV.h(f);if($CLJS.n(u))return u;u=$CLJS.pP.h(f);if($CLJS.n(u))return u;u=$CLJS.jQ.h(f);return $CLJS.n(u)?u:$CLJS.cF.h(f)}())?$CLJS.X0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.UA($CLJS.Py);if($CLJS.n($CLJS.TA("metabase.lib.field",
m))){var t=$CLJS.tE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.SA("metabase.lib.field",m,$CLJS.Lw(),t):$CLJS.SA("metabase.lib.field",m,$CLJS.Lw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?bra(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.N3($CLJS.Kk.l(l,$CLJS.aj,$CLJS.H([$CLJS.iL,$CLJS.$W,$CLJS.UW])),null),$CLJS.T,function(){var m=$CLJS.WZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.YK,$CLJS.JZ):l:null}finally{r7=d}};
t7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.EP);var f=$CLJS.I(c,2,null);c=$CLJS.jl.l($CLJS.H([function(){var k=$CLJS.CB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.CB,k],null):null}(),function(){var k=$CLJS.KA($CLJS.Hi,$CLJS.CB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Hi,k],null):null}(),function(){var k=$CLJS.FR.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.$W,k],null):null}(),function(){var k=$CLJS.zG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.UW,k],null):null}(),$CLJS.Fd(f)?function(){var k=$CLJS.r2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.iu,$CLJS.WK,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.iu,$CLJS.WK,$CLJS.T,f],null):function(){var k=cra(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.iu,$CLJS.WK,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.N3(c,d):c};u7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.UW);return $CLJS.n($CLJS.n(b)?$CLJS.Hd($CLJS.CH,b):b)?$CLJS.yj:$CLJS.KA($CLJS.Hi,$CLJS.CB)(a)};
$CLJS.v7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.KE),l=$CLJS.J.g(f,$CLJS.CB),m=$CLJS.J.g(f,$CLJS.FR),t=$CLJS.J.g(f,$CLJS.Hi);e=$CLJS.J.g(f,$CLJS.EP);var u=$CLJS.J.g(f,$CLJS.PN),v=$CLJS.J.g(f,$CLJS.zG),x=$CLJS.jl.l;k=new $CLJS.h(null,2,[$CLJS.iu,$CLJS.WK,$CLJS.p1,k],null);f=$CLJS.yE.h(f);a=$CLJS.n(f)?f:$CLJS.m0.j(a,b,d);c=x.call($CLJS.jl,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.yE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Hi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.CB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.UW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.$W,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.hL,u):m;return $CLJS.n(e)?$CLJS.N3(u,e):u};w7=function(a,b,c){return $CLJS.n($CLJS.Re(function(d){return $CLJS.E.g($CLJS.ti.h(d),c)},a))?$CLJS.Uk.g(function(d){var e=$CLJS.Hd(d,b)?$CLJS.Kk.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ti.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
x7=function(a){var b=$CLJS.n($CLJS.W1.h(a))?null:function(){var d=$CLJS.YK.h(a),e=new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.$Z,null,$CLJS.f_,null,$CLJS.JZ,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.jl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.KE,$CLJS.p.h($CLJS.UE()),$CLJS.CB,$CLJS.CB.h(a),$CLJS.Hi,u7(a)],null),function(){var d=$CLJS.P1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.EP,d],null):null}(),function(){var d=$CLJS.UW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.zG,d],
null):null}(),function(){var d=$CLJS.YW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.YW,d],null):null}(),function(){var d=$CLJS.$W.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.FR,d],null):null}(),function(){var d=$CLJS.hL.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.PN,d],null):null}()]));b=($CLJS.n(b)?$CLJS.KA($CLJS.WZ,$CLJS.T):$CLJS.KA($CLJS.aj,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,c,b],null)};
dra=function(a,b){return $CLJS.lf(function(c){return $CLJS.E.g($CLJS.YK.h(c),$CLJS.QZ)},$CLJS.X0.v(a,b,$CLJS.QW(a,b),new $CLJS.h(null,3,[$CLJS.F0,!1,$CLJS.L0,!0,$CLJS.B0,!1],null)))};y7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.s7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);era=new $CLJS.M(null,"earliest","earliest",-1928154382);fra=new $CLJS.M(null,"latest","latest",24323665);var ira;$CLJS.C1.m(null,$CLJS.tG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zh.h(b),$CLJS.B1(c,new $CLJS.h(null,2,[$CLJS.zG,$CLJS.zh,$CLJS.FR,ara],null)),a],null)});var r7=!1,z7=function z7(a,b){var d=$CLJS.I1(a,y7.h(b));a=$CLJS.n(y7.h(d))?z7.g?z7.g(a,d):z7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Vk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.Q0.m(null,$CLJS.WK,function(a,b,c){return u7(c)});$CLJS.Q0.m(null,$CLJS.tG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.zG);$CLJS.I(c,2,null);c=t7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.UW,d):c;return $CLJS.R0.j(a,b,c)});$CLJS.S0.m(null,$CLJS.WK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.S0.m(null,$CLJS.tG,function(a,b,c){var d=t7(a,b,c);b=$CLJS.v7(a,b,d,c);return $CLJS.n(y7.h(b))?z7(a,b):b});
$CLJS.M0.m(null,$CLJS.WK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.yE),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.ti),l=$CLJS.J.g(c,$CLJS.$W),m=$CLJS.J.g(c,$CLJS.i_),t=$CLJS.J.g(c,$CLJS.hL),u=$CLJS.J.g(c,$CLJS.iL);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.N1.g($CLJS.HB,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.n0)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.I1(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.OA($CLJS.yE.h($CLJS.p0.j(a,b,t)),$CLJS.bX,"")):(u=$CLJS.O1(a,u),a=$CLJS.m0.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.P1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.kB($CLJS.OA($CLJS.gh(k),"-"," ")),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.B5(l,c),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):m});
$CLJS.M0.m(null,$CLJS.tG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.FR);var k=$CLJS.J.g(f,$CLJS.EP),l=$CLJS.J.g(f,$CLJS.zG);f=$CLJS.J.g(f,$CLJS.PN);$CLJS.I(c,2,null);c=t7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.i_,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ti,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.$W,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.hL,f):e;return $CLJS.n(e)?$CLJS.m0.v(a,b,e,d):$CLJS.VE("[Unknown Field]")});
$CLJS.N0.m(null,$CLJS.WK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.N0.m(null,$CLJS.tG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=t7(a,b,c);return $CLJS.n(c)?$CLJS.O0.j(a,b,c):"unknown_field"});
$CLJS.T0.m(null,$CLJS.WK,function(a,b,c){return $CLJS.jl.l($CLJS.H([function(){var d=$CLJS.bi($CLJS.T0,$CLJS.di);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.YK.h(c),$CLJS.$Z)?function(){var d=$CLJS.h_.h(c);return $CLJS.n(d)?(d=$CLJS.i0(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.ZW,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.yE,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.g1.m(null,$CLJS.tG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.zG.h(b)});
$CLJS.g1.m(null,$CLJS.WK,function(a){return $CLJS.UW.h(a)});
$CLJS.f1.m(null,$CLJS.tG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Hd($CLJS.CH,b),e=$CLJS.BC($CLJS.YW,$CLJS.Hi,$CLJS.CB)(c);c=$CLJS.R.l(c,$CLJS.zG,b,$CLJS.H([$CLJS.Hi,d?$CLJS.yj:e,$CLJS.YW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,c,a],null)}b=$CLJS.YW.h(c);c=$CLJS.n(b)?$CLJS.Kk.g($CLJS.R.j(c,$CLJS.Hi,b),$CLJS.YW):c;c=$CLJS.Kk.g(c,$CLJS.zG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,c,a],null)});
$CLJS.f1.m(null,$CLJS.WK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.UW,b,$CLJS.H([$CLJS.YW,$CLJS.BC($CLJS.YW,$CLJS.Hi,$CLJS.CB)(a)])):$CLJS.Kk.l(a,$CLJS.UW,$CLJS.H([$CLJS.YW]))});$CLJS.i1.m(null,$CLJS.tG,function(a,b,c){return $CLJS.j1.j(a,b,t7(a,b,c))});
$CLJS.i1.m(null,$CLJS.WK,function(a,b,c){if($CLJS.Qk.g($CLJS.YK.h(c),$CLJS.QZ)){a=$CLJS.KA($CLJS.Hi,$CLJS.CB)(c);b=null==c?null:$CLJS.cO.h(c);if(null==b)var d=null;else try{var e=$CLJS.CD.h($CLJS.oj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,era),l=$CLJS.J.g(f,fra),m=$CLJS.rna.l($CLJS.H([$CLJS.d1.h(k),$CLJS.d1.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.bs.g?$CLJS.bs.g(1,m):$CLJS.bs.call(null,1,m))?$CLJS.Wi:$CLJS.n($CLJS.bs.g?$CLJS.bs.g(31,m):$CLJS.bs.call(null,31,m))?$CLJS.ju:$CLJS.n($CLJS.bs.g?
$CLJS.bs.g(365,m):$CLJS.bs.call(null,365,m))?$CLJS.qj:$CLJS.zj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.DC(a,$CLJS.CD)?$CLJS.zna:$CLJS.DC(a,$CLJS.dD)?$CLJS.yna:$CLJS.DC(a,$CLJS.bE)?$CLJS.xna:$CLJS.Cf;d=$CLJS.n(d)?w7(e,$CLJS.di,d):e;return $CLJS.n($CLJS.UW.h(c))?w7(d,$CLJS.u0,$CLJS.UW.h(c)):d}return $CLJS.Cf});
$CLJS.E5.m(null,$CLJS.tG,function(a){var b=null==a?null:$CLJS.RE(a);b=null==b?null:$CLJS.FR.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.iu,$CLJS.D5,$CLJS.H([$CLJS.C5,function(c,d){return t7(c,d,a)}]))});$CLJS.E5.m(null,$CLJS.WK,function(a){var b=null==a?null:$CLJS.$W.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.iu,$CLJS.D5,$CLJS.H([$CLJS.C5,$CLJS.Ue(a)]))});$CLJS.y5.m(null,$CLJS.tG,function(a,b){return $CLJS.SE(a,$CLJS.QE,$CLJS.H([$CLJS.FR,b]))});
$CLJS.y5.m(null,$CLJS.WK,function(a,b){return $CLJS.QE(a,$CLJS.$W,b)});$CLJS.F5.m(null,$CLJS.tG,function(a,b,c){return $CLJS.G5.j(a,b,t7(a,b,c))});
$CLJS.F5.m(null,$CLJS.WK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Hi);var d=$CLJS.J.g(b,$CLJS.cO),e=$CLJS.J.g(b,$CLJS.Wj);if($CLJS.Qk.g($CLJS.YK.h(b),$CLJS.QZ)){var f=function(){var m=null==a?null:$CLJS.G1($CLJS.f0(a));m=null==m?null:$CLJS.qA.h(m);return null==m?null:$CLJS.Hd(m,$CLJS.FR)}(),k=$CLJS.LA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oj,$CLJS.Qj],null)),l=$CLJS.o7(b);return function u(t){return new $CLJS.ne(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.lc(v),
z=$CLJS.D(x),C=$CLJS.qe(z);return function(){for(var K=0;;)if(K<z){var S=$CLJS.kd(x,K),V=C,Z=S;S=q7(S,l)?$CLJS.R.j(Z,$CLJS.u0,!0):Z;V.add(S);K+=1}else return!0}()?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}var G=$CLJS.A(v);return $CLJS.ee(function(){var K=G;return q7(G,l)?$CLJS.R.j(K,$CLJS.u0,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Va(function(){if($CLJS.n(f)){var t=$CLJS.bo.h(k);return $CLJS.n(t)?$CLJS.lk.h(k):t}return f}())?null:$CLJS.DC(e,$CLJS.Aj)?$CLJS.Uk.g(p7,
new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.A5(),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("Bin every 0.1 degrees"),$CLJS.xE,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.NE,$CLJS.NE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("Bin every 1 degree"),$CLJS.xE,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.NE,$CLJS.NE,1],null)],null),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("Bin every 10 degrees"),$CLJS.xE,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.NE,$CLJS.NE,10],null)],null),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("Bin every 20 degrees"),
$CLJS.xE,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.NE,$CLJS.NE,20],null)],null)],null)):$CLJS.DC(c,$CLJS.Qj)&&!$CLJS.DC(e,$CLJS.oi)?$CLJS.Uk.g(p7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.A5(),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("10 bins"),$CLJS.xE,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.wE,$CLJS.wE,10],null)],null),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("50 bins"),$CLJS.xE,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.wE,$CLJS.wE,50],null)],null),new $CLJS.h(null,2,[$CLJS.yE,$CLJS.VE("100 bins"),$CLJS.xE,new $CLJS.h(null,2,
[$CLJS.OE,$CLJS.wE,$CLJS.wE,100],null)],null)],null)):null)}return $CLJS.Cf});$CLJS.zW.m(null,$CLJS.tG,function(a){return a});
$CLJS.zW.m(null,$CLJS.WK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.YK);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.KE,$CLJS.p.h($CLJS.UE()),$CLJS.Hi,$CLJS.KA($CLJS.Hi,$CLJS.CB)(a)],null),a=$CLJS.p1.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,b,a],null);case "source/expressions":return n7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.CE.h(a))?n7(a):x7(a);default:return x7(a)}});
$CLJS.gra=function(){function a(e,f,k){k=$CLJS.Fe($CLJS.Uk.g($CLJS.BW,k));var l=dra(e,f),m=$CLJS.eg.j($CLJS.bh,$CLJS.$l(function(t){return $CLJS.x2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Cf);m=$CLJS.Tk.g(m,l);k=$CLJS.n(k)?$CLJS.eg.j(k,$CLJS.hf.h($CLJS.BW),m):null;return $CLJS.aX.l(e,f,$CLJS.QE,$CLJS.H([$CLJS.cF,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.A7=function(){function a(d,e){return $CLJS.cF.h($CLJS.QW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.hra=function(){function a(d,e){var f=$CLJS.X0.v(d,e,$CLJS.QW(d,e),new $CLJS.h(null,3,[$CLJS.F0,!1,$CLJS.L0,!1,$CLJS.B0,!1],null)),k=$CLJS.A7.g(d,e);return $CLJS.td(k)?$CLJS.Uk.g(function(l){return $CLJS.R.j(l,$CLJS.YZ,!0)},f):$CLJS.L3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
ira=function(){function a(d,e,f){var k=$CLJS.QW(d,e),l=$CLJS.E.g($CLJS.pC(f),$CLJS.iG)?$CLJS.V0:$CLJS.X0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.x2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.jra=function(){function a(d,e,f){f=$CLJS.FZ.j(d,e,f);return ira.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();