var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var vra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.CB);return $CLJS.Re(function(c){return $CLJS.DC(b,c)},$CLJS.vF)},K7=new $CLJS.M("metabase.lib.order-by","order-by-clause","metabase.lib.order-by/order-by-clause",774979647);$CLJS.BF($CLJS.qG,K7);$CLJS.BF($CLJS.jG,K7);
$CLJS.P0.m(null,$CLJS.$R,function(a,b){var c=$CLJS.Fe($CLJS.$R.h($CLJS.QW(a,b)));return $CLJS.n(c)?$CLJS.tE("Sorted by {0}",$CLJS.H([$CLJS.n2($CLJS.VE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.m0.v(a,b,v,$CLJS.n0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);
return $CLJS.ee($CLJS.m0.v(a,b,t,$CLJS.n0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.M0.m(null,K7,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.m0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "asc":return $CLJS.tE("{0} ascending",$CLJS.H([a]));case "desc":return $CLJS.tE("{0} descending",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.T0.m(null,K7,function(a,b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.R.j($CLJS.p0.j(a,b,c),$CLJS.A0,d)});var L7,wra=$CLJS.Xe($CLJS.N),xra=$CLJS.Xe($CLJS.N),yra=$CLJS.Xe($CLJS.N),zra=$CLJS.Xe($CLJS.N),Ara=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));L7=new $CLJS.ei($CLJS.Fh.g("metabase.lib.order-by","order-by-clause-method"),$CLJS.pC,Ara,wra,xra,yra,zra);L7.m(null,K7,function(a){return $CLJS.AW(a)});
L7.m(null,$CLJS.di,function(a){if(null==a)throw $CLJS.ii($CLJS.VE("Can''t order by nil"),$CLJS.N);return $CLJS.AW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qG,$CLJS.BW(a)],null))});
$CLJS.M7=function(){function a(d,e){d=L7.h(d);e=$CLJS.n(e)?e:$CLJS.qG;return $CLJS.R.j($CLJS.Df(d),0,e)}function b(d){return c.g?c.g(d,$CLJS.qG):c.call(null,d,$CLJS.qG)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.N7=function(){function a(e,f,k,l){f=$CLJS.n(f)?f:-1;var m=function(){var t=L7.h(k);return $CLJS.n(l)?$CLJS.R.j($CLJS.Df(t),0,l):t}();return $CLJS.aX.l(e,f,$CLJS.Vk,$CLJS.H([$CLJS.$R,function(t){return $CLJS.be.g($CLJS.Df(t),m)}]))}function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.v?d.v(e,-1,f,null):d.call(null,e,-1,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,
e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();$CLJS.O7=function(){function a(d,e){return $CLJS.Fe($CLJS.J.g($CLJS.QW(d,e),$CLJS.$R))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.P7=function(){function a(d,e){var f=$CLJS.Fe($CLJS.$5.g(d,e)),k=$CLJS.Fe($CLJS.M4.g(d,e));f=$CLJS.n($CLJS.n(f)?f:k)?$CLJS.gf.g(f,k):function(){var t=$CLJS.QW(d,e);return $CLJS.X0.v(d,e,t,new $CLJS.h(null,1,[$CLJS.J0,!1],null))}();var l=$CLJS.lf(vra,f);f=$CLJS.hf.g(function(t){$CLJS.I(t,0,null);$CLJS.I(t,1,null);return $CLJS.I(t,2,null)},$CLJS.O7.g(d,e));if($CLJS.td(l))return null;if($CLJS.td(f))return $CLJS.Df(l);var m=$CLJS.eg.j($CLJS.N,$CLJS.Sk.g($CLJS.hf.h($CLJS.BW),$CLJS.QY(function(t,u){u=
$CLJS.x2.v(d,e,u,l);return $CLJS.n(u)?new $CLJS.P(null,2,5,$CLJS.Q,[u,t],null):null})),f);return $CLJS.Uk.g(function(t){var u=m.h?m.h(t):m.call(null,t);return $CLJS.n(u)?$CLJS.R.j(t,$CLJS.w0,u):t},l)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Q7=new $CLJS.h(null,2,[$CLJS.qG,$CLJS.jG,$CLJS.jG,$CLJS.qG],null);