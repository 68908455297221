var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./malli.registry.js");require("./malli.util.js");
'use strict';var eE,fE,gE,Rea,hE,jE,kE;
$CLJS.dE=function(a,b){return function(){function c(l,m,t){l=null==l?b:l;return a.j?a.j(l,m,t):a.call(null,l,m,t)}function d(l,m){l=null==l?b:l;return a.g?a.g(l,m):a.call(null,l,m)}function e(l){l=null==l?b:l;return a.h?a.h(l):a.call(null,l)}var f=null,k=function(){function l(t,u,v,x){var z=null;if(3<arguments.length){z=0;for(var C=Array(arguments.length-3);z<C.length;)C[z]=arguments[z+3],++z;z=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,z)}function m(t,u,v,x){return $CLJS.Se.N(a,null==t?b:t,u,
v,x)}l.A=3;l.B=function(t){var u=$CLJS.A(t);t=$CLJS.B(t);var v=$CLJS.A(t);t=$CLJS.B(t);var x=$CLJS.A(t);t=$CLJS.Lc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,v)}throw Error("Invalid arity: "+arguments.length);
};f.A=3;f.B=k.B;f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};eE=function(a,b){this.db=a;this.Ah=b;this.C=393216;this.I=0};fE=function(a){for(var b=null;;){var c;a=$CLJS.Bo.g(a,b);a=c=$CLJS.Un(a)?$CLJS.On(a):a;if(!$CLJS.Un(c))return a}};gE=function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return!($CLJS.xd(a)&&!0===$CLJS.Ws.h(a))};
Rea=function(a,b,c){var d=fE($CLJS.Bo.g(a,c));return $CLJS.Iq($CLJS.Fn(d),$CLJS.Cn(d),function(){var e=$CLJS.En(d);return b.h?b.h(e):b.call(null,e)}(),function(){var e=$CLJS.Dn($CLJS.Bo.g(d,null));return $CLJS.n(e)?e:c}())};hE=function(a){return function(b,c,d){var e=$CLJS.y(c);b=$CLJS.A(e);e=$CLJS.B(e);c=$CLJS.Uk.g(function(f){return $CLJS.Bo.g(f,d)},c);return new $CLJS.P(null,3,5,$CLJS.Q,[c,$CLJS.Uk.g($CLJS.pt,c),$CLJS.fb(function(f,k){return a.j?a.j(f,k,d):a.call(null,f,k,d)},b,e)],null)}};
$CLJS.iE=function(a,b,c,d,e,f,k,l,m,t,u,v,x,z,C,G,K,S,V){this.form=a;this.options=b;this.Ri=c;this.forms=d;this.U=e;this.Yd=f;this.za=k;this.children=l;this.min=m;this.Eb=t;this.Rb=u;this.parent=v;this.type=x;this.cache=z;this.re=C;this.max=G;this.Ve=K;this.Ne=S;this.Zh=V;this.C=393216;this.I=0};jE=function(a,b,c,d,e,f,k,l,m){this.Ve=a;this.re=b;this.type=c;this.min=d;this.max=e;this.Yd=f;this.Eb=k;this.Rb=l;this.Ne=m;this.C=393216;this.I=0};
kE=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.oj),d=$CLJS.J.g(b,$CLJS.bo),e=$CLJS.J.g(b,$CLJS.lk),f=$CLJS.J.g(b,$CLJS.Et),k=$CLJS.J.g(b,$CLJS.jq),l=$CLJS.J.g(b,$CLJS.Ti);return new jE(a,b,c,d,e,f,k,l,new $CLJS.h(null,1,[$CLJS.oj,$CLJS.Pp],null))};$CLJS.X=function(a,b){$CLJS.Oh.v(lE,$CLJS.R,a,b);$CLJS.Ye($CLJS.mE,$CLJS.N);return null};eE.prototype.P=function(a,b){return new eE(this.db,b)};eE.prototype.O=function(){return this.Ah};eE.prototype.sd=$CLJS.Bc;
eE.prototype.cd=function(a,b){return $CLJS.qn($CLJS.sn($CLJS.q(this.db)),b)};var nE=function nE(a){switch(arguments.length){case 2:return nE.g(arguments[0],arguments[1]);case 3:return nE.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};nE.g=function(a,b){return nE.j(a,b,null)};
nE.j=function(a,b,c){function d(v,x){return $CLJS.E.g($CLJS.kk,v)?new $CLJS.P(null,2,5,$CLJS.Q,[null,x],null):$CLJS.gf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Xq.h(x)],null),$CLJS.Zq.h(x))}function e(v,x){return $CLJS.n($CLJS.n(v)?x:v)?$CLJS.jl.l($CLJS.H([v,x])):$CLJS.n(v)?v:x}a=$CLJS.n(a)?fE($CLJS.Bo.g(a,c)):null;b=$CLJS.n(b)?fE($CLJS.Bo.g(b,c)):null;var f=$CLJS.n(a)?$CLJS.io.h(a):null,k=$CLJS.n(b)?$CLJS.io.h(b):null,l=$CLJS.O(c),m=$CLJS.J.j(l,$CLJS.zt,function(v,x){return x}),t=$CLJS.J.j(l,$CLJS.Ct,
function(v,x){return x});if(null==a)return b;if(null==b)return a;if($CLJS.Va(function(){var v=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Ms,null,$CLJS.kk,null],null),null);v=v.h?v.h(f):v.call(null,f);return $CLJS.n(v)?(v=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Ms,null,$CLJS.kk,null],null),null),v.h?v.h(k):v.call(null,k)):v}()))return m.j?m.j(a,b,c):m.call(null,a,b,c);if($CLJS.E.g(f,$CLJS.kk)&&$CLJS.E.g(k,$CLJS.kk)){l=e($CLJS.Cn(a),$CLJS.Cn(b));var u=$CLJS.Xe($CLJS.bh);a=$CLJS.fb(function(v,x){var z=
$CLJS.I(x,0,null);if($CLJS.n(function(){var C=$CLJS.q(u);return C.h?C.h(z):C.call(null,z)}()))return $CLJS.fb(function(C,G){var K=$CLJS.I(G,0,null),S=$CLJS.be.g;if($CLJS.E.g(K,z)){K=$CLJS.I(G,0,null);var V=$CLJS.I(G,1,null),Z=$CLJS.I(G,2,null);$CLJS.I(x,0,null);var ha=$CLJS.I(x,1,null),ra=$CLJS.I(x,2,null);G=gE(G);var Ma=gE(x);G=t.g?t.g(G,Ma):t.call(null,G,Ma);V=$CLJS.jl.l($CLJS.H([V,ha]));ra=new $CLJS.P(null,3,5,$CLJS.Q,[K,$CLJS.R.j(V,$CLJS.Ws,$CLJS.Va(G)),nE.j?nE.j(Z,ra,c):nE.call(null,Z,ra,c)],
null);K=$CLJS.I(ra,0,null);Z=$CLJS.I(ra,1,null);ra=$CLJS.I(ra,2,null);K=$CLJS.Va(ra)?new $CLJS.P(null,2,5,$CLJS.Q,[K,Z],null):$CLJS.n($CLJS.n(Z)?!1===$CLJS.Ws.h(Z)&&$CLJS.E.g(1,$CLJS.D(Z)):Z)?new $CLJS.P(null,2,5,$CLJS.Q,[K,ra],null):$CLJS.Va($CLJS.y(Z))?new $CLJS.P(null,2,5,$CLJS.Q,[K,ra],null):!1===$CLJS.Ws.h(Z)?new $CLJS.P(null,3,5,$CLJS.Q,[K,$CLJS.Kk.g(Z,$CLJS.Ws),ra],null):new $CLJS.P(null,3,5,$CLJS.Q,[K,Z,ra],null)}else K=G;return S.call($CLJS.be,C,K)},$CLJS.Cf,v);$CLJS.Oh.j(u,$CLJS.be,z);return $CLJS.be.g(v,
x)},$CLJS.Cf,$CLJS.eg.g($CLJS.En(a),$CLJS.En(b)));return $CLJS.Iq($CLJS.kk,l,a,c)}return function(v,x){var z=$CLJS.y(v);v=$CLJS.A(z);var C=$CLJS.B(z);z=$CLJS.A(C);C=$CLJS.B(C);var G=$CLJS.y(x);x=$CLJS.A(G);var K=$CLJS.B(G);G=$CLJS.A(K);K=$CLJS.B(K);return $CLJS.Iq($CLJS.Ms,e(v,x),$CLJS.gf.l(new $CLJS.P(null,1,5,$CLJS.Q,[nE.j(z,G,c)],null),C,$CLJS.H([K])),c)}(d(f,a),d(k,b))};nE.A=3;
var oE=function oE(a){switch(arguments.length){case 2:return oE.g(arguments[0],arguments[1]);case 3:return oE.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};oE.g=function(a,b){return oE.j(a,b,null)};
oE.j=function(a,b,c){return nE.j(a,b,$CLJS.Vk.j($CLJS.Vk.j(c,$CLJS.zt,$CLJS.dE($CLJS.Td,function(d,e,f){var k=$CLJS.E.g($CLJS.pt.g(d,null),$CLJS.pt.g(e,null));return $CLJS.n(k)?d:$CLJS.Bo.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,d,e],null),f)})),$CLJS.Ct,$CLJS.dE($CLJS.Td,function(d,e){return $CLJS.n(d)?e:d})))};oE.A=3;
var pE=function pE(a){switch(arguments.length){case 2:return pE.g(arguments[0],arguments[1]);case 3:return pE.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};pE.g=function(a,b){return pE.j(a,b,null)};pE.j=function(a,b,c){var d=$CLJS.fh(b);return Rea(a,function(e){return $CLJS.lf(function(f){f=$CLJS.I(f,0,null);return d.h?d.h(f):d.call(null,f)},e)},c)};pE.A=3;$CLJS.g=$CLJS.iE.prototype;
$CLJS.g.P=function(a,b){return new $CLJS.iE(this.form,this.options,this.Ri,this.forms,this.U,this.Yd,this.za,this.children,this.min,this.Eb,this.Rb,this.parent,this.type,this.cache,this.re,this.max,this.Ve,this.Ne,b)};$CLJS.g.O=function(){return this.Zh};$CLJS.g.Ya=$CLJS.Bc;$CLJS.g.ya=function(){return $CLJS.zn(this.za)};$CLJS.g.La=function(){return this.options};$CLJS.g.Ta=function(a,b,c,d){return $CLJS.jp(this,new $CLJS.P(null,1,5,$CLJS.Q,[this.za],null),b,c,d)};$CLJS.g.$a=function(){return this.U};
$CLJS.g.ua=function(){return this.children};$CLJS.g.Sa=function(){return $CLJS.q(this.form)};$CLJS.g.Ra=function(a,b){return $CLJS.An(this.za,b)};$CLJS.g.Za=function(){return this.parent};$CLJS.g.Wa=$CLJS.Bc;$CLJS.g.ia=$CLJS.ua(5);$CLJS.g.je=$CLJS.Bc;$CLJS.g.Ld=function(){return this.za};$CLJS.g=jE.prototype;$CLJS.g.P=function(a,b){return new jE(this.Ve,this.re,this.type,this.min,this.max,this.Yd,this.Eb,this.Rb,b)};$CLJS.g.O=function(){return this.Ne};$CLJS.g.Xa=$CLJS.Bc;$CLJS.g.Pa=function(){return this.type};
$CLJS.g.Qa=function(){return this.Eb};$CLJS.g.Oa=function(a,b,c,d){var e=this;$CLJS.co(e.type,b,c,e.min,e.max);a=function(){var m=$CLJS.Df(c);return e.Rb.j?e.Rb.j(b,m,d):e.Rb.call(null,b,m,d)}();var f=$CLJS.I(a,0,null),k=$CLJS.I(a,1,null),l=$CLJS.I(a,2,null);return new $CLJS.iE(new $CLJS.Jh(function(){return $CLJS.Ho(e.type,b,k,d)}),d,a,k,b,e.Yd,l,f,e.min,e.Eb,e.Rb,this,e.type,$CLJS.Go(),e.re,e.max,e.Ve,e.Ne,new $CLJS.h(null,1,[$CLJS.oj,$CLJS.nq],null))};
$CLJS.qE=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);var lE;$CLJS.mE=$CLJS.Xe($CLJS.N);
lE=$CLJS.Xe($CLJS.jl.l($CLJS.H([$CLJS.Us(),new $CLJS.h(null,3,[$CLJS.At,kE(new $CLJS.h(null,2,[$CLJS.oj,$CLJS.At,$CLJS.Ti,hE(nE)],null)),$CLJS.yt,kE(new $CLJS.h(null,2,[$CLJS.oj,$CLJS.yt,$CLJS.Ti,hE(oE)],null)),$CLJS.Ft,kE(new $CLJS.h(null,5,[$CLJS.oj,$CLJS.Ft,$CLJS.Et,1,$CLJS.bo,2,$CLJS.lk,2,$CLJS.Ti,function(a,b,c){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vk.j(b,0,function(d){return $CLJS.Bo.g(d,c)}),$CLJS.Vk.j(b,0,function(d){return $CLJS.pt.g(d,c)}),$CLJS.Se.g(pE,$CLJS.be.g(b,c))],null)}],null))],
null)])));$CLJS.Ye($CLJS.zl,$CLJS.sn(new eE(lE,$CLJS.N)));