var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var TV,VV,WV,Fka,$V,aW,bW,Hka,cW,dW,eW,gW,hW,iW,jW,kW,lW,nW,oW,pW,qW,rW,uW,vW,Gka;TV=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Tk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.me(c)}),a)};
$CLJS.UV=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Se.g($CLJS.JC,function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v);$CLJS.ue(t,$CLJS.TE(x,function(){return function(z){return $CLJS.Kk.l(TV(z),$CLJS.CB,$CLJS.H([$CLJS.Hi]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),e($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k);return $CLJS.ee($CLJS.TE(u,function(){return function(v){return $CLJS.Kk.l(TV(v),$CLJS.CB,$CLJS.H([$CLJS.Hi]))}}(u,k,f,b)),e($CLJS.Lc(k)))}return null}},null,null)}(a)}())};
VV=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cf,a],null)],null);for(var c=$CLJS.Cf;;){var d=$CLJS.rd(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.sd(a);a=function(t,u,v,x,z,C,G,K){return function(S){return $CLJS.eg.j(v,$CLJS.hf.h(function(V,Z,ha,ra,Ma){return function(zb){var Oa=$CLJS.I(zb,0,null);zb=$CLJS.I(zb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.be.g(Ma,Oa),zb],null)}}(t,u,v,x,z,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.be.g(c,l)):a=$CLJS.xd(k)?a(k):$CLJS.wd(k)?a($CLJS.gm($CLJS.Vs,k)):m}else return c}};WV=function(a,b,c){return $CLJS.zd(c)&&$CLJS.E.g(a,$CLJS.A(c))&&!$CLJS.Hd(b,$CLJS.J.g(c,2))};$CLJS.XV=function(a){var b=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.Sk.g($CLJS.CE,$CLJS.hd)),$CLJS.jQ.h(a));return VV($CLJS.Kk.l(a,$CLJS.ON,$CLJS.H([$CLJS.yV])),function(c){return WV($CLJS.zB,b,c)})};
$CLJS.YV=function(a){var b=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.Sk.g($CLJS.KE,$CLJS.hd)),$CLJS.iG.h(a));return VV($CLJS.Kk.l(a,$CLJS.ON,$CLJS.H([$CLJS.yV])),function(c){return WV($CLJS.iG,b,c)})};Fka=function(a){function b(d){return $CLJS.jf(c,$CLJS.H([$CLJS.ON.h(d)]))}function c(d){return $CLJS.ee($CLJS.gF.h(d),$CLJS.jf(b,$CLJS.H([$CLJS.xL.h(d)])))}return $CLJS.n($CLJS.ZV.h(a))?$CLJS.Ue(!0):$CLJS.fh(b(a))};
$V=function(a){if($CLJS.wd(a))for(var b=$CLJS.Ue(!1),c=$CLJS.y(a),d=$CLJS.A(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.A(v),z=$CLJS.B(v),C=x,G=z,K=$CLJS.KA(m,Fka(C));f=$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function(S,V,Z,ha,ra,Ma){return function eb(Oa,Ya){try{if($CLJS.zd(Ya)&&3===$CLJS.D(Ya))try{var Sa=$CLJS.F(Ya,0);if($CLJS.he(Sa,$CLJS.tG))try{var mb=$CLJS.F(Ya,1);if($CLJS.n($CLJS.EP.h(mb)))try{var Ia=$CLJS.F(Ya,1);if(null!=Ia?Ia.C&256||$CLJS.Bc===Ia.mf||(Ia.C?0:$CLJS.$a($CLJS.wb,
Ia)):$CLJS.$a($CLJS.wb,Ia))try{var bc=$CLJS.J.j(Ia,$CLJS.EP,$CLJS.OV);if($CLJS.n($CLJS.Te(ha)(bc))){var Zd=$CLJS.J.g(Ia,$CLJS.EP);$CLJS.F(Ya,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Ma),": no join named ",$CLJS.Mh.l($CLJS.H([Zd]))].join("")],null)}throw $CLJS.Y;}catch(qd){if(qd instanceof Error){var Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;
}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)throw $CLJS.Y;throw Id;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Id=qd;if(Id===$CLJS.Y)return $CLJS.LV(eb,Oa,Ya);throw Id;}throw qd;}}}(f,k,l,K,m,t,u,v,x,z,C,G,b,0,a,a,c,d,e,d,e)($CLJS.Cf,$CLJS.Kk.l(C,$CLJS.ON,$CLJS.H([Gka]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};aW=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);bW=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Hka=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);cW=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
dW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);eW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.fW=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);gW=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
hW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);iW=new $CLJS.r(null,"refs","refs",80480079,null);jW=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);kW=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);lW=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.mW=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);nW=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);oW=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);pW=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);qW=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
rW=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.sW=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.ZV=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.tW=new $CLJS.M(null,"filters","filters",974726919);uW=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);vW=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.wW=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Gka=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(jW,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.mW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uQ,$CLJS.Wa],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gt,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,$CLJS.Gl],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wO,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.DE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RQ,new $CLJS.h(null,1,[$CLJS.Ws,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qV],null)],null)],null));$CLJS.X(oW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xG],null));
$CLJS.X(aW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.bo,1],null),oW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.Bt,"Breakouts must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.UV},bW,$CLJS.Ig([$CLJS.Yi,$CLJS.T,$CLJS.rk,$CLJS.wi,$CLJS.Vi,$CLJS.pj,$CLJS.Bk,$CLJS.vi,$CLJS.mk,$CLJS.qk],[$CLJS.CV,cW,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[iW],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.UV)?$CLJS.UV.H:null]))],null)],null));
$CLJS.X(pW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.bo,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.Bt,":fields must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.UV},bW,$CLJS.Ig([$CLJS.Yi,$CLJS.T,$CLJS.rk,$CLJS.wi,$CLJS.Vi,$CLJS.pj,$CLJS.Bk,$CLJS.vi,$CLJS.mk,$CLJS.qk],[$CLJS.CV,cW,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,
1,5,$CLJS.Q,[iW],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.UV)?$CLJS.UV.H:null]))],null)],null));$CLJS.X(qW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.SF],null),$CLJS.kk,$CLJS.ak],null)],null));
$CLJS.X(nW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.bo,1],null),qW],null));
var xW=$CLJS.KA(function(a){a=$CLJS.A($CLJS.XV(a));if($CLJS.n(a)){var b=$CLJS.LA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Mh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.A($CLJS.YV(a));if($CLJS.n(a)){var b=$CLJS.LA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.X(rW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,2,[$CLJS.Bt,"Valid references for a single query stage",$CLJS.Dt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Fj);return xW(a)}],null),$CLJS.Te(xW)],null));
$CLJS.X($CLJS.wW,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ms,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.fW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ON,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jQ,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.hG,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),aW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),pW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tW,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),nW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$R,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DL],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pP,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZV,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fL],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.Bt,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Hd(a,$CLJS.TQ)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,
new $CLJS.h(null,1,[$CLJS.Bt,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Te($CLJS.rE($CLJS.pP,$CLJS.ZV))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rW],null)],null));$CLJS.X(dW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.At,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aL],null)],null)],null)],null));
$CLJS.X(eW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.At,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fL],null)],null)],null)],null));$CLJS.X(hW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eW],null)],null));
$CLJS.X(kW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.Bt,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Te($CLJS.KA($CLJS.pP,$CLJS.ZV))],null)],null));$CLJS.X(lW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.mW,$CLJS.fW],null));
$CLJS.X(Hka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,lW],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ls,new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.iu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wW],null)],null)],null)],null));
$CLJS.X(vW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,lW],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ls,new $CLJS.h(null,1,[$CLJS.Qi,$CLJS.iu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hW],null)],null)],null)],null));$CLJS.X(gW,kW);
$CLJS.X(uW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,2,[$CLJS.Bt,"Valid references for all query stages",$CLJS.Dt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Fj);return $V.h?$V.h(a):$V.call(null,a)}],null),$CLJS.Te($V)],null));
$CLJS.X($CLJS.tL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gW],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uW],null)],null));
$CLJS.X($CLJS.VL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.sW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.gL,$CLJS.lL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tL],null)],null)],null),$CLJS.yka],null));