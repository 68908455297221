var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var DW,Ska,Tka,LW,Uka,Vka,Xka,HW,Yka,Wka;DW=function(a){return a+1};$CLJS.EW=function(a){if("string"===typeof a){var b=$CLJS.nh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.mm(a));};
Ska=function(a){var b=new $CLJS.Ea,c=$CLJS.Iu;$CLJS.Iu=new $CLJS.sc(b);try{var d=$CLJS.Iu,e=$CLJS.Va($CLJS.Qv(d)),f=$CLJS.Iu;$CLJS.Iu=e?$CLJS.Hv(d):d;try{d=$CLJS.Sv;$CLJS.Sv=!0;try{$CLJS.Uv(a)}finally{$CLJS.Sv=d}$CLJS.E.g(0,$CLJS.Pu($CLJS.Iu,$CLJS.Ru))||$CLJS.ac($CLJS.Iu,"\n");$CLJS.Ou()}finally{$CLJS.Iu=f}$CLJS.uh($CLJS.p.h(b))}finally{$CLJS.Iu=c}};$CLJS.FW=function(a,b){return $CLJS.Se.j($CLJS.fa,a,b)};
$CLJS.GW=function(a){var b=$CLJS.Ev;$CLJS.Ev=120;try{var c=new $CLJS.Ea,d=$CLJS.Qa,e=$CLJS.Ra;$CLJS.Qa=!0;$CLJS.Ra=function(f){return c.append(f)};try{Ska(a)}finally{$CLJS.Ra=e,$CLJS.Qa=d}return $CLJS.p.h(c)}finally{$CLJS.Ev=b}};
Tka=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.PV,$CLJS.Td),c=$CLJS.J.j(a,$CLJS.MV,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Xe($CLJS.N),e=$CLJS.Xe($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var z=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),C=$CLJS.q(d);return C.h?C.h(z):C.call(null,z)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.Oh.v(e,$CLJS.Vk,u,$CLJS.dE(DW,0)),u);if($CLJS.E.g(x,1))return $CLJS.Oh.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var z=t,C=$CLJS.p.h(x);return c.g?c.g(z,C):c.call(null,z,C)}()}}function k(m){var t=l,u=t.g;null==HW&&(HW=$CLJS.Xe(0));var v=$CLJS.Fh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Oh.g(HW,DW))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.IW=function(a){return $CLJS.zd(a)&&1<$CLJS.D(a)&&$CLJS.A(a)instanceof $CLJS.M&&$CLJS.xd($CLJS.hd(a))&&$CLJS.Hd($CLJS.hd(a),$CLJS.KE)};$CLJS.JW=function(a){return $CLJS.IW(a)?$CLJS.CE.h($CLJS.RE(a)):null};$CLJS.KW=function(a,b){return $CLJS.SE($CLJS.IW(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,2,[$CLJS.KE,$CLJS.p.h($CLJS.UE()),$CLJS.Hi,$CLJS.YE(a)],null),a],null),$CLJS.R,$CLJS.H([$CLJS.CE,b]))};
LW=function(a,b,c){var d=$CLJS.Hd(a,b)?$CLJS.Vk.j(a,b,function(e){return $CLJS.zd(e)&&$CLJS.E.g($CLJS.A(e),$CLJS.Ms)?$CLJS.Df($CLJS.bf(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Hd(a,b)?$CLJS.KV(d,$CLJS.Ee([b,c])):d};Uka=function(a){var b=$CLJS.hm(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pP,$CLJS.TQ],null));b=MW.h?MW.h(b):MW.call(null,b);return $CLJS.AW($CLJS.R.l(LW($CLJS.Kk.l(a,$CLJS.pP,$CLJS.H([$CLJS.TQ])),$CLJS.bS,$CLJS.AL),$CLJS.iu,$CLJS.sL,$CLJS.H([$CLJS.xL,b])))};
Vka=function(a){return $CLJS.Uk.g(Uka,a)};Xka=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Vk.j($CLJS.Pl(a)?new $CLJS.h(null,1,[$CLJS.NW,a],null):a,$CLJS.NW,function(b){return $CLJS.Uk.g(function(c){return $CLJS.R.j($CLJS.MA(c,$CLJS.mB),$CLJS.iu,$CLJS.WK)},b)}),$CLJS.iu,Wka):null};
$CLJS.OW=function(a){if($CLJS.E.g($CLJS.iu.h(a),$CLJS.sW))return a;var b=$CLJS.oj.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.jl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.iu,$CLJS.sW,$CLJS.xL,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.iu,$CLJS.mW],null),$CLJS.KV($CLJS.uQ.h(a),new $CLJS.h(null,1,[$CLJS.QF,$CLJS.uQ],null))]))],null)],null),$CLJS.Kk.l(a,$CLJS.oj,$CLJS.H([$CLJS.uQ]))]));case "query":return $CLJS.jl.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.iu,$CLJS.sW,$CLJS.xL,MW($CLJS.QF.h(a))],null),$CLJS.Kk.l(a,$CLJS.oj,$CLJS.H([$CLJS.QF]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.PW=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.xL);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.ii($CLJS.tE("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[Yka,$CLJS.D(a)],null));return c};
$CLJS.QW=function(a,b){a=$CLJS.OW(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.xL);return $CLJS.J.g($CLJS.Df(c),$CLJS.PW(a,b))};$CLJS.RW=function(a){return"string"===typeof a&&(a=$CLJS.oh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.EW(a)):null};$CLJS.TW=function(){return $CLJS.Sk.g(SW,Tka($CLJS.H([$CLJS.PV,$CLJS.iB,$CLJS.MV,function(a,b){return SW.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};HW=null;
$CLJS.UW=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.VW=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.WW=new $CLJS.M(null,"join","join",-758861890);$CLJS.XW=new $CLJS.M(null,"stage","stage",1843544772);Yka=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.NW=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.YW=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$CLJS.ZW=new $CLJS.M(null,"table","table",-564943036);Wka=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.$W=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var MW,SW;
MW=function MW(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.TQ),d=$CLJS.J.g(a,$CLJS.bP);c=$CLJS.n(c)?MW.h?MW.h(c):MW.call(null,c):$CLJS.Cf;d=Xka(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.gM(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.yV],null),d):c;d=$CLJS.n($CLJS.uQ.h(a))?$CLJS.mW:$CLJS.fW;a=$CLJS.jl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.iu,d],null),$CLJS.Kk.l(a,$CLJS.TQ,$CLJS.H([$CLJS.bP]))]));a=$CLJS.y($CLJS.ON.h(a))?$CLJS.Vk.j(a,$CLJS.ON,Vka):a;a=LW(a,$CLJS.YF,$CLJS.tW);return $CLJS.be.g(c,a)};
$CLJS.aX=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.OW(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.xL);d=$CLJS.PW(c,d);e=$CLJS.Se.N($CLJS.Vk,$CLJS.Df(k),d,e,f);return $CLJS.R.j(c,$CLJS.xL,e)}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=
b;return a}();
SW=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.yW.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.bX=RegExp(" id$","i");