var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var m1,Ana,Bna,n1,o1;$CLJS.k1=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Cf),b))};m1=function(a){return $CLJS.n(l1)?["(",$CLJS.p.h(a),")"].join(""):a};Ana=function(a,b,c){var d=$CLJS.QW(a,b);a=$CLJS.X0.j(a,b,d);var e=$CLJS.iB(c);return $CLJS.Re(function(f){return $CLJS.E.g($CLJS.iB($CLJS.T.h(f)),e)},a)};
Bna=function(a,b){var c=$CLJS.Vk.v(a,$CLJS.jQ,$CLJS.dE($CLJS.be,$CLJS.Cf),b);return $CLJS.zd($CLJS.cF.h(a))?$CLJS.Vk.v(c,$CLJS.cF,$CLJS.be,$CLJS.AW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zB,$CLJS.N,$CLJS.JW(b)],null))):c};n1=function(a,b,c){var d=$CLJS.JW(c);return $CLJS.R.l($CLJS.l0.j(a,b,c),$CLJS.YK,$CLJS.QZ,$CLJS.H([$CLJS.T,d,$CLJS.yE,d]))};o1=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);
$CLJS.p1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var q1=function(){function a(d,e,f){var k=$CLJS.QW(d,e);k=$CLJS.FH($CLJS.Sk.g($CLJS.ch([f]),$CLJS.JW),$CLJS.jQ.h(k));if($CLJS.n(k))return k;throw $CLJS.ii($CLJS.tE("No expression named {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.lS,f,$CLJS.QF,d,$CLJS.NL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.Q0.m(null,$CLJS.zB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=q1.j(a,b,c);return $CLJS.R0.j(a,b,c)});$CLJS.S0.m(null,$CLJS.zB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.iu,$CLJS.WK,$CLJS.p1,$CLJS.KE.h(d),$CLJS.T,e,$CLJS.CE,e,$CLJS.yE,$CLJS.m0.j(a,b,c),$CLJS.CB,$CLJS.R0.j(a,b,c),$CLJS.YK,$CLJS.QZ],null)});$CLJS.M0.m(null,$CLJS.uB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.M0.m(null,$CLJS.rB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.M0.m(null,$CLJS.tB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.M0.m(null,$CLJS.JB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.M0.m(null,$CLJS.zB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.N0.m(null,$CLJS.zB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var l1=!1,Cna=new $CLJS.h(null,4,[$CLJS.js,"+",$CLJS.ut,"-",$CLJS.ks,"×",$CLJS.OG,"÷"],null),r1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.js,$CLJS.ut,$CLJS.OG,$CLJS.ks],null)),s1=null,t1=0,u1=0;;)if(u1<t1){var Dna=s1.X(null,u1);$CLJS.BF(Dna,o1);u1+=1}else{var v1=$CLJS.y(r1);if(v1){var w1=v1;if($CLJS.Ad(w1)){var x1=$CLJS.lc(w1),Ena=$CLJS.mc(w1),Fna=x1,Gna=$CLJS.D(x1);r1=Ena;s1=Fna;t1=Gna}else{var Hna=$CLJS.A(w1);$CLJS.BF(Hna,o1);r1=$CLJS.B(w1);s1=null;t1=0}u1=0}else break}
$CLJS.M0.m(null,o1,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(Cna,c);a:{var e=l1;l1=!0;try{var f=$CLJS.Mt([" ",$CLJS.gh(c)," "].join(""),$CLJS.hf.g($CLJS.lu($CLJS.m0,a,b),d));break a}finally{l1=e}f=void 0}return m1(f)});$CLJS.N0.m(null,o1,function(){return"expression"});
$CLJS.Q0.m(null,$CLJS.LH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.DF,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.R0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($CLJS.R0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.M0.m(null,$CLJS.rJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.m0.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.e1.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.JA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=m1(f);return[a," ",d.call(b,f)].join("")});
$CLJS.N0.m(null,$CLJS.rJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.O0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.e1.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.JA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.M0.m(null,$CLJS.wG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.m0.v(a,b,e,d)});$CLJS.N0.m(null,$CLJS.wG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.O0.j(a,b,d)});
$CLJS.Ina=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(Ana(d,e,f)))throw $CLJS.ii("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.lS,f],null));return $CLJS.aX.l(d,e,Bna,$CLJS.H([$CLJS.KW($CLJS.CW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.v=a;return c}();$CLJS.Jna=function(){function a(d,e){var f=$CLJS.Fe($CLJS.jQ.h($CLJS.QW(d,e)));return null==f?null:$CLJS.Uk.g($CLJS.lu(n1,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.y1=function(){function a(d,e){return $CLJS.Fe($CLJS.jQ.h($CLJS.QW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.zW.m(null,$CLJS.zB,function(a){return a});
$CLJS.Kna=function(){function a(d,e,f){var k=$CLJS.eg.g($CLJS.N,$CLJS.gm(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JW(t),m],null)},$CLJS.y1.g(d,e))),l=$CLJS.QW(d,e);d=$CLJS.X0.j(d,e,l);return $CLJS.Fe($CLJS.k1(function(m){return $CLJS.Va(f)||$CLJS.Qk.g($CLJS.YK.h(m),$CLJS.QZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Lna=function(){function a(d,e,f){return $CLJS.BW(n1(d,e,q1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();