var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var r4,t4,u4,v4,Hpa;r4=function(a,b,c){var d=$CLJS.QW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.iG);d=$CLJS.FH($CLJS.Sk.j($CLJS.ch([c]),$CLJS.KE,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.ii($CLJS.tE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.ek,c,$CLJS.QF,a,$CLJS.NL,b],null));return d};$CLJS.s4=function(a){return $CLJS.l2($CLJS.GG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
t4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);u4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);v4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Hpa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.P0.m(null,$CLJS.iG,function(a,b){var c=$CLJS.Fe($CLJS.iG.h($CLJS.QW(a,b)));return $CLJS.n(c)?$CLJS.n2($CLJS.VE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.m0.v(a,b,v,$CLJS.n0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.m0.v(a,
b,t,$CLJS.n0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.S0.m(null,$CLJS.iG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.CB);e=$CLJS.J.g(e,$CLJS.Hi);c=$CLJS.I(c,2,null);c=r4(a,b,c);return $CLJS.jl.l($CLJS.H([$CLJS.l0.j(a,b,c),new $CLJS.h(null,2,[$CLJS.YK,$CLJS.qL,$CLJS.p1,$CLJS.KE.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.CB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Hi,e],null):null]))});
$CLJS.M0.m(null,$CLJS.iG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.m0.v(a,b,r4(a,b,c),d)});$CLJS.BF(u4,v4);
for(var w4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.by,$CLJS.uG],null)),x4=null,y4=0,z4=0;;)if(z4<y4){var Ipa=x4.X(null,z4);$CLJS.BF(Ipa,u4);z4+=1}else{var A4=$CLJS.y(w4);if(A4){var B4=A4;if($CLJS.Ad(B4)){var C4=$CLJS.lc(B4),Jpa=$CLJS.mc(B4),Kpa=C4,Lpa=$CLJS.D(C4);w4=Jpa;x4=Kpa;y4=Lpa}else{var Mpa=$CLJS.A(B4);$CLJS.BF(Mpa,u4);w4=$CLJS.B(B4);x4=null;y4=0}z4=0}else break}
$CLJS.M0.m(null,u4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.m0.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.tE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.tE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.VE("Count");case "cum-count":return $CLJS.VE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.N0.m(null,u4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.S0.m(null,u4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.bi($CLJS.S0,v4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Wj,$CLJS.eD)});$CLJS.M0.m(null,$CLJS.cG,function(){return $CLJS.VE("Case")});
$CLJS.N0.m(null,$CLJS.cG,function(){return"case"});$CLJS.BF(t4,v4);for(var D4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.nG,$CLJS.PG,$CLJS.ZF,$CLJS.lk,$CLJS.HG,$CLJS.bo,$CLJS.IG,$CLJS.GG,$CLJS.$F],null)),E4=null,F4=0,G4=0;;)if(G4<F4){var Npa=E4.X(null,G4);$CLJS.BF(Npa,t4);G4+=1}else{var H4=$CLJS.y(D4);if(H4){var I4=H4;if($CLJS.Ad(I4)){var J4=$CLJS.lc(I4),Opa=$CLJS.mc(I4),Ppa=J4,Qpa=$CLJS.D(J4);D4=Opa;E4=Ppa;F4=Qpa}else{var Rpa=$CLJS.A(I4);$CLJS.BF(Rpa,t4);D4=$CLJS.B(I4);E4=null;F4=0}G4=0}else break}
$CLJS.N0.m(null,t4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.M0.m(null,t4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.m0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.tE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.tE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.tE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.tE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.tE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.tE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.tE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.tE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.tE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.M0.m(null,$CLJS.eG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.tE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.m0.v(a,b,e,d)]))});$CLJS.N0.m(null,$CLJS.eG,function(){return"percentile"});
$CLJS.BF($CLJS.eG,v4);$CLJS.M0.m(null,$CLJS.NG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.tE("Sum of {0} matching condition",$CLJS.H([$CLJS.m0.v(a,b,e,d)]))});$CLJS.N0.m(null,$CLJS.NG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.O0.j(a,b,d))].join("")});$CLJS.BF($CLJS.NG,v4);$CLJS.M0.m(null,$CLJS.AG,function(){return $CLJS.VE("Share of rows matching condition")});
$CLJS.N0.m(null,$CLJS.AG,function(){return"share"});$CLJS.BF($CLJS.AG,v4);$CLJS.M0.m(null,$CLJS.CG,function(){return $CLJS.VE("Count of rows matching condition")});$CLJS.N0.m(null,$CLJS.CG,function(){return"count-where"});$CLJS.BF($CLJS.CG,v4);
$CLJS.S0.m(null,v4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.jl.l;d=$CLJS.n(d)?$CLJS.hm($CLJS.l0.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jL],null)):null;var f=$CLJS.bi($CLJS.S0,$CLJS.di);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.jl,$CLJS.H([d,a]))});
$CLJS.Spa=function(){function a(d){return $CLJS.l2($CLJS.by,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.l2($CLJS.by,$CLJS.Cf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.zW.m(null,$CLJS.iG,function(a){return a});
$CLJS.K4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.pC(f),$CLJS.bL))f=$CLJS.BW(f);else return $CLJS.o2(d,e,$CLJS.iG,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.L4=function(){function a(d,e){return $CLJS.Fe($CLJS.iG.h($CLJS.QW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.M4=function(){function a(d,e){var f=$CLJS.Fe($CLJS.iG.h($CLJS.QW(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(k){var l=$CLJS.l0.j(d,e,k),m=$CLJS.R.l,t=$CLJS.CB.h(l);return m.call($CLJS.R,$CLJS.k2(l,$CLJS.Hi,$CLJS.n(t)?t:$CLJS.Cj),$CLJS.YK,$CLJS.qL,$CLJS.H([$CLJS.p1,$CLJS.KE.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.M0.m(null,$CLJS.bH,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.YG);return $CLJS.yE.h(a.o?a.o():a.call(null))});$CLJS.T0.m(null,$CLJS.bH,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.mG);b=$CLJS.J.g(d,$CLJS.YG);c=$CLJS.J.g(d,$CLJS.WG);d=$CLJS.J.g(d,$CLJS.YZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.K0,$CLJS.hB(a),$CLJS.H([$CLJS.I0,c]));return null!=d?$CLJS.R.j(a,$CLJS.u0,d):a});
$CLJS.Tpa=function(){function a(d,e){var f=function(){var m=$CLJS.qA.h($CLJS.G1($CLJS.f0(d)));return $CLJS.n(m)?m:$CLJS.bh}(),k=$CLJS.QW(d,e),l=$CLJS.X0.j(d,e,k);return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Sk.j($CLJS.kf(function(m){m=$CLJS.$G.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.$l(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.WG),u=$CLJS.J.g(m,$CLJS.UG);if($CLJS.Va(t))return m;if($CLJS.E.g(u,$CLJS.mi))return $CLJS.R.j(m,$CLJS.NW,l);t=$CLJS.Fe($CLJS.k1(function(v){return $CLJS.I_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.NW,t):null}),$CLJS.hf.h(function(m){return $CLJS.R.j(m,$CLJS.iu,$CLJS.bH)})),$CLJS.cH))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Upa=function(){function a(d,e){return $CLJS.AW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG.h(d),$CLJS.N,$CLJS.CW.h(e)],null))}function b(d){if($CLJS.Va($CLJS.WG.h(d)))return $CLJS.AW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG.h(d),$CLJS.N],null));var e=$CLJS.mG.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.ii(e,new $CLJS.h(null,1,[Hpa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();