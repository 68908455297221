var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var PY,Rla,RY,Sla,Tla,Ula,TY,Wla,Xla,Yla,Zla,WY,XY,YY,ZY,aZ,ama,bZ,cZ,bma,cma,dma,VY,Vla,dZ,eZ,$la,gZ;PY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
Rla=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oj.j?$CLJS.oj.j(k,l,m):$CLJS.oj.call(null,k,l,m),$CLJS.ql.j?$CLJS.ql.j(k,l,m):$CLJS.ql.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oj.g?$CLJS.oj.g(k,l):$CLJS.oj.call(null,k,l),$CLJS.ql.g?$CLJS.ql.g(k,l):$CLJS.ql.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oj.h?$CLJS.oj.h(k):$CLJS.oj.call(null,k),$CLJS.ql.h?$CLJS.ql.h(k):$CLJS.ql.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oj.o?$CLJS.oj.o():$CLJS.oj.call(null),$CLJS.ql.o?$CLJS.ql.o():$CLJS.ql.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.oj,m,t,u,v),$CLJS.Se.N($CLJS.ql,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.QY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
RY=function(a){return $CLJS.aM($CLJS.wW)(a,$CLJS.Cf,$CLJS.Cf)};Sla=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.iG,b),$CLJS.rt(2,2,c))};Tla=function(a,b){var c=$CLJS.im(a,$CLJS.RQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.Lt.g?$CLJS.Lt.g(c,b):$CLJS.Lt.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.RQ,b)}return a};Ula=function(a){return $CLJS.gf.g($CLJS.XV(a),$CLJS.YV(a))};
$CLJS.SY=function(a,b){return $CLJS.IW(a)&&$CLJS.E.g($CLJS.A(a),b)};TY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Zs,null,$CLJS.wl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Gu(2,c):$CLJS.Gu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?PY(2,c):PY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.HX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Tk.h($CLJS.Dl),l):$CLJS.xd(k)?$CLJS.RA($CLJS.Wa,l):l}):$CLJS.Kk.g(a,f)};
Wla=function(a){return $CLJS.fb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return TY(b,Vla,d)},a,Ula(a))};
Xla=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.xL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(Rla(),$CLJS.lf($CLJS.Sk.j(UY,$CLJS.A,$CLJS.ql),$CLJS.ct.h(RY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=TY(d,f,k),m=$CLJS.UA($CLJS.Py);if($CLJS.n($CLJS.TA("metabase.lib.convert",m))){var t=$CLJS.LB.l($CLJS.H([$CLJS.Kz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.LB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.fM(RY(d));$CLJS.SA("metabase.lib.convert",
m,$CLJS.FW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.Kz,z.call(x,$CLJS.H([C]))])),$CLJS.LB.l($CLJS.H([$CLJS.zz,$CLJS.GW($CLJS.A($CLJS.nu(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=Wla(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.xL.h(a))-1))return a;b+=1}else a=$CLJS.Vk.N(a,$CLJS.xL,$CLJS.R,b,d)}};
Yla=function(a){var b=$CLJS.TW();return $CLJS.Uk.g(function(c){return $CLJS.E.g($CLJS.gF.h(c),"__join")?$CLJS.Vk.j(c,$CLJS.gF,b):c},a)};Zla=function(a){return"string"===typeof $CLJS.pP.h(a)?$CLJS.Kk.g($CLJS.R.j(a,$CLJS.ZV,$CLJS.RW($CLJS.pP.h(a))),$CLJS.pP):a};WY=function(a,b){var c=$CLJS.J.j(a,b,VY);if($CLJS.E.g(c,VY))throw $CLJS.ii(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[$la,a,$CLJS.fx,b],null));return c};
XY=function(){return $CLJS.Tk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};YY=function(a){return $CLJS.eg.j($CLJS.N,XY(),a)};ZY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Sk.g(XY(),$CLJS.Tk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Hi)})),a))};
aZ=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.$Y),c);a=$CLJS.E.g(a,$CLJS.cG)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.di,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(ZY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FM,a,b],null):a};ama=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Sk.g($CLJS.hf.h(function(b){return $CLJS.MA(b,$CLJS.QB)}),$CLJS.hf.h($CLJS.$Y)),$CLJS.NW.h(a))};
bZ=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.xL);a=$CLJS.A($CLJS.fb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.$Y.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.TQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.bP,ama(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.yV.h(c)],null)},null,a));return $CLJS.n($CLJS.uQ.h(a))?$CLJS.KV(a,new $CLJS.h(null,1,[$CLJS.uQ,$CLJS.QF],null)):a};
cZ=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.eX(a,b,$CLJS.Sk.g($CLJS.$Y,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.eX(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ms],null),$CLJS.hf.h($CLJS.$Y),e)}):d;return $CLJS.KV(a,$CLJS.Ee([b,c]))};bma=function(a){var b=$CLJS.ZV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Kk.g(a,$CLJS.ZV),$CLJS.pP,["card__",$CLJS.p.h(b)].join("")):a};
cma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);dma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);VY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Vla=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);dZ=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
eZ=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.fZ=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);$la=new $CLJS.M(null,"m","m",1632677161);gZ=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var UY,ema,fma,gma,hma,ima,jma,kma,lma,mma,nma;$CLJS.hZ=$CLJS.N;$CLJS.iZ=$CLJS.N;UY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.tW,null,$CLJS.cF,null,$CLJS.ON,null,$CLJS.jQ,null,$CLJS.hG,null,$CLJS.$R,null,$CLJS.iG,null],null),null);ema=$CLJS.Xe($CLJS.N);fma=$CLJS.Xe($CLJS.N);gma=$CLJS.Xe($CLJS.N);hma=$CLJS.Xe($CLJS.N);ima=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.jZ=new $CLJS.ei($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.pC,ima,ema,fma,gma,hma);$CLJS.jZ.m(null,$CLJS.di,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.AW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.jZ),d))}else b=a;return b});$CLJS.jZ.m(null,$CLJS.sW,function(a){return a});
$CLJS.jZ.m(null,$CLJS.fW,function(a){var b=$CLJS.jZ.h($CLJS.iG.h(a)),c=$CLJS.Fe($CLJS.Uk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.KW($CLJS.jZ.h(t),u)},$CLJS.jQ.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.jm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.jm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.KE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.iZ,k=$CLJS.hZ;$CLJS.iZ=d;$CLJS.hZ=e;try{var l=Sla(Zla(a),b,$CLJS.H([$CLJS.jQ,c])),m=$CLJS.fb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Vk.j(t,u,$CLJS.jZ)},l,$CLJS.Lk.l(UY,$CLJS.iG,$CLJS.H([$CLJS.jQ])));return $CLJS.n($CLJS.ON.h(m))?$CLJS.Vk.j(m,$CLJS.ON,Yla):m}finally{$CLJS.hZ=k,$CLJS.iZ=f}});$CLJS.jZ.m(null,$CLJS.mW,function(a){return Tla(a,function(b){return $CLJS.eX(b,$CLJS.NF,$CLJS.jZ)})});
$CLJS.jZ.m(null,$CLJS.sL,function(a){a=$CLJS.Vk.j($CLJS.Vk.j(a,$CLJS.AL,$CLJS.jZ),$CLJS.xL,$CLJS.jZ);var b=$CLJS.n($CLJS.cF.h(a))?$CLJS.Vk.j(a,$CLJS.cF,function(c){return $CLJS.Pl(c)?$CLJS.Uk.g($CLJS.jZ,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.gF.h(a))?$CLJS.R.j(b,$CLJS.gF,"__join"):b});$CLJS.jZ.m(null,$CLJS.wB,function(a){return $CLJS.Uk.g($CLJS.jZ,a)});
$CLJS.jZ.m(null,$CLJS.yB,function(a){return $CLJS.n($CLJS.oj.h(a))?Xla($CLJS.R.j($CLJS.Vk.j($CLJS.OW(a),$CLJS.xL,function(b){return $CLJS.Uk.g($CLJS.jZ,b)}),$CLJS.fZ,!0)):$CLJS.Lt(a,$CLJS.jZ)});$CLJS.jZ.m(null,$CLJS.tG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.AW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,a,b],null))});
$CLJS.jZ.m(null,$CLJS.Fj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.KV(a,new $CLJS.h(null,3,[$CLJS.qD,$CLJS.CB,$CLJS.OO,$CLJS.Wj,$CLJS.hS,$CLJS.FE],null));var c=$CLJS.R.j;var d=$CLJS.Hi.h(a);$CLJS.n(d)||(d=$CLJS.CB.h(a),d=$CLJS.n(d)?d:$CLJS.YE(b));a=c.call($CLJS.R,a,$CLJS.Hi,d);return $CLJS.AW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,a,b],null))});
$CLJS.jZ.m(null,$CLJS.cG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.di.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,$CLJS.Kk.g(c,$CLJS.di),$CLJS.Uk.g($CLJS.jZ,b)],null);b=$CLJS.AW(b);return null!=a?$CLJS.be.g(b,$CLJS.jZ.h(a)):b});$CLJS.jZ.m(null,$CLJS.zB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.AW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.jZ.m(null,$CLJS.iG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.AW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,WY($CLJS.iZ,c)],null))});$CLJS.jZ.m(null,$CLJS.FM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.jZ.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.jl.l($CLJS.H([c,a]))],null),d)});jma=$CLJS.Xe($CLJS.N);kma=$CLJS.Xe($CLJS.N);
lma=$CLJS.Xe($CLJS.N);mma=$CLJS.Xe($CLJS.N);nma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.$Y=new $CLJS.ei($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.pC,nma,jma,kma,lma,mma);
$CLJS.$Y.m(null,$CLJS.di,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.$Y,d);a=ZY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.$Y,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.Lt(YY(a),$CLJS.$Y):
a;return a});for(var kZ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[gZ,eZ],null)),lZ=null,mZ=0,nZ=0;;)if(nZ<mZ){var oma=lZ.X(null,nZ);$CLJS.BF(oma,dZ);nZ+=1}else{var oZ=$CLJS.y(kZ);if(oZ){var pZ=oZ;if($CLJS.Ad(pZ)){var qZ=$CLJS.lc(pZ),pma=$CLJS.mc(pZ),qma=qZ,rma=$CLJS.D(qZ);kZ=pma;lZ=qma;mZ=rma}else{var sma=$CLJS.A(pZ);$CLJS.BF(sma,dZ);kZ=$CLJS.B(pZ);lZ=null;mZ=0}nZ=0}else break}
for(var rZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.by,$CLJS.nG,$CLJS.CG,$CLJS.ZF,$CLJS.lk,$CLJS.HG,$CLJS.bo,$CLJS.eG,$CLJS.AG,$CLJS.IG,$CLJS.GG,$CLJS.NG],null)),sZ=null,tZ=0,uZ=0;;)if(uZ<tZ){var tma=sZ.X(null,uZ);$CLJS.BF(tma,gZ);uZ+=1}else{var vZ=$CLJS.y(rZ);if(vZ){var wZ=vZ;if($CLJS.Ad(wZ)){var xZ=$CLJS.lc(wZ),uma=$CLJS.mc(wZ),vma=xZ,wma=$CLJS.D(xZ);rZ=uma;sZ=vma;tZ=wma}else{var xma=$CLJS.A(wZ);$CLJS.BF(xma,gZ);rZ=$CLJS.B(wZ);sZ=null;tZ=0}uZ=0}else break}
for(var yZ=$CLJS.y($CLJS.yf([$CLJS.js,$CLJS.ut,$CLJS.ks,$CLJS.OG,$CLJS.cG,$CLJS.wG,$CLJS.UF,$CLJS.QG,$CLJS.LF,$CLJS.gG,$CLJS.PF,$CLJS.rG,$CLJS.yG,$CLJS.RF,$CLJS.hJ,$CLJS.jJ,$CLJS.Qy,$CLJS.dJ,$CLJS.lJ,$CLJS.YI,$CLJS.pJ,$CLJS.cJ,$CLJS.WI,$CLJS.oJ,$CLJS.wJ,$CLJS.sJ,$CLJS.bJ,$CLJS.zJ,$CLJS.rJ,$CLJS.xJ,$CLJS.TF,$CLJS.MF,$CLJS.RG,$CLJS.iI,$CLJS.vG,$CLJS.$x,$CLJS.JG,$CLJS.LG,$CLJS.SG,$CLJS.Ot,$CLJS.Ut],!0)),zZ=null,AZ=0,BZ=0;;)if(BZ<AZ){var yma=zZ.X(null,BZ);$CLJS.BF(yma,eZ);BZ+=1}else{var CZ=$CLJS.y(yZ);
if(CZ){var DZ=CZ;if($CLJS.Ad(DZ)){var EZ=$CLJS.lc(DZ),zma=$CLJS.mc(DZ),Ama=EZ,Bma=$CLJS.D(EZ);yZ=zma;zZ=Ama;AZ=Bma}else{var Cma=$CLJS.A(DZ);$CLJS.BF(Cma,eZ);yZ=$CLJS.B(DZ);zZ=null;AZ=0}BZ=0}else break}$CLJS.$Y.m(null,dZ,function(a){return aZ(a)});$CLJS.$Y.m(null,$CLJS.yB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Sk.g(XY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.$Y.h(b)],null)})),a)});
$CLJS.$Y.m(null,$CLJS.iG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=ZY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iG,WY($CLJS.hZ,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.DV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.hi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.$Y.m(null,$CLJS.wB,function(a){return $CLJS.Uk.g($CLJS.$Y,a)});$CLJS.$Y.m(null,$CLJS.tG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,$CLJS.$Y.h(a),ZY(b)],null)});
$CLJS.$Y.m(null,$CLJS.Fj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=ZY($CLJS.KV(b,new $CLJS.h(null,3,[$CLJS.CB,$CLJS.qD,$CLJS.Wj,$CLJS.OO,$CLJS.FE,$CLJS.hS],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,a,b],null)});
$CLJS.$Y.m(null,$CLJS.sL,function(a){var b=YY(a);a=0==$CLJS.gF.h(a).lastIndexOf("__join",0)?$CLJS.Kk.g(b,$CLJS.gF):b;return $CLJS.jl.l($CLJS.H([$CLJS.Lt($CLJS.Kk.l(a,$CLJS.xL,$CLJS.H([$CLJS.AL])),$CLJS.$Y),cZ($CLJS.hm(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.AL],null)),$CLJS.AL,$CLJS.bS),bZ(a)]))});
$CLJS.$Y.m(null,$CLJS.fW,function(a){var b=$CLJS.iG.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.jm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.jm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.iZ,e=$CLJS.hZ;$CLJS.iZ=c;$CLJS.hZ=b;try{return $CLJS.fb(function(f,k){return $CLJS.eX(f,
k,$CLJS.$Y)},cZ($CLJS.eX($CLJS.eX(bma(YY(a)),$CLJS.iG,function(f){return $CLJS.Uk.g(aZ,f)}),$CLJS.jQ,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.$Y.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JW(C),$CLJS.E.g($CLJS.Fj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.$Y.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JW(x),$CLJS.E.g($CLJS.Fj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.tW,$CLJS.YF),$CLJS.Lk.l(UY,$CLJS.iG,$CLJS.H([$CLJS.tW,$CLJS.jQ])))}finally{$CLJS.hZ=e,$CLJS.iZ=d}});$CLJS.$Y.m(null,$CLJS.mW,function(a){return $CLJS.Lt(YY(a),$CLJS.$Y)});
$CLJS.$Y.m(null,$CLJS.sW,function(a){try{var b=YY(a),c=$CLJS.FN.h(b),d=bZ(b),e=$CLJS.E.g($CLJS.iu.h($CLJS.id($CLJS.xL.h(a))),$CLJS.mW)?$CLJS.uQ:$CLJS.QF;return $CLJS.jl.l($CLJS.H([$CLJS.Lt($CLJS.Kk.l(b,$CLJS.xL,$CLJS.H([$CLJS.FN,$CLJS.fZ])),$CLJS.$Y),function(){var k=$CLJS.Ee([$CLJS.oj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.FN,c):k}()]))}catch(k){var f=k;throw $CLJS.hi(function(){var l=$CLJS.DV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,
"Error converting MLv2 query to legacy query: %s",l)}(),new $CLJS.h(null,1,[$CLJS.QF,a],null),f);}});
$CLJS.FZ=function(){function a(d,e,f){f=$CLJS.OY(null,$CLJS.Xz(f,$CLJS.H([$CLJS.Bi,!0])));var k=$CLJS.QW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.iG);k=$CLJS.eg.j($CLJS.N,$CLJS.jm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.jm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.KE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.iZ,t=$CLJS.hZ;$CLJS.iZ=k;$CLJS.hZ=l;try{try{return $CLJS.jZ.h(f)}catch(v){var u=v;throw $CLJS.hi(function(){var x=$CLJS.DV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.QF,d,$CLJS.NL,e,dma,f,cma,$CLJS.iZ],null),u);}}finally{$CLJS.hZ=t,$CLJS.iZ=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();