import { useMemo } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { Form, FormProvider } from "metabase/forms";
import FormCheckBox from "metabase/core/components/FormCheckBox";
import FormErrorMessage from "metabase/core/components/FormErrorMessage";
import FormInput from "metabase/core/components/FormInput";
import FormSubmitButton from "metabase/core/components/FormSubmitButton";
import * as Errors from "metabase/lib/errors";
import { OktaAuth } from '@okta/okta-auth-js';
import MetabaseSettings from "metabase/lib/settings";
import type { LoginData } from "../../types";

// Получение настроек для Okta из MetabaseSettings
const oktaIssuer = MetabaseSettings.get("okta-issuer");
const oktaClientId = MetabaseSettings.get("okta-auth-client-id");
const oktaDomain = MetabaseSettings.get("okta-domain");
const isOktaEnabled = MetabaseSettings.get("okta-auth-enabled");

console.log(oktaClientId)
console.log(oktaIssuer)
console.log(oktaDomain)
console.log(isOktaEnabled)

const oktaAuth = new OktaAuth({
  issuer: oktaIssuer || "https://{yourOktaDomain}.com/oauth2/default",
  clientId: oktaClientId || "defaultClientId",
  redirectUri: oktaDomain || "http://localhost:3000/callback",
  responseType: "code",
  pkce: false
});

const LOGIN_SCHEMA = Yup.object().shape({
  username: Yup.string()
    .required(Errors.required)
    .when("$isLdapEnabled", {
      is: false,
      then: schema => schema.email(Errors.email),
    }),
  password: Yup.string().required(Errors.required),
  remember: Yup.boolean(),
});

interface LoginFormProps {
  isLdapEnabled: boolean;
  hasSessionCookies: boolean;
  onSubmit: (data: LoginData) => void;
}

export const LoginForm = ({
  isLdapEnabled,
  hasSessionCookies,
  onSubmit,
}: LoginFormProps): JSX.Element => {
  const initialValues = useMemo(
    () => ({
      username: "",
      password: "",
      remember: !hasSessionCookies,
    }),
    [hasSessionCookies],
  );

  const validationContext = useMemo(
    () => ({
      isLdapEnabled,
    }),
    [isLdapEnabled],
  );

  const handleOktaLogin = async () => {
    try {
      oktaAuth.signInWithRedirect();
    } catch (error) {
      console.error('Ошибка при входе через Okta', error);
    }
  };

  return (
    <FormProvider
      initialValues={initialValues}
      validationSchema={LOGIN_SCHEMA}
      validationContext={validationContext}
      onSubmit={onSubmit}
    >
      <Form>
        <FormInput
          name="username"
          title={isLdapEnabled ? t`Username or email address` : t`Email address`}
          type={isLdapEnabled ? "input" : "email"}
          placeholder="nicetoseeyou@email.com"
        />
        <FormInput
          name="password"
          title={t`Password`}
          type="password"
          placeholder={t`Shhh...`}
        />
        {!hasSessionCookies && <FormCheckBox name="remember" title={t`Remember me`} />}
        <FormSubmitButton title={t`Sign in`} primary fullWidth />
        <FormErrorMessage />
        {isOktaEnabled && (
          <div style={{ marginTop: '1rem' }}>
            <button type="button" onClick={handleOktaLogin} style={{
              background: 'black',
              color: 'white',
              border: 'none',
              width: '100%',
              padding: '10px 0',
              cursor: 'pointer',
              borderRadius: '6px',
            }}>{t`Sign in with Okta`}</button>
          </div>
        )}
      </Form>
    </FormProvider>
  );
};
