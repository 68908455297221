var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var GZ,Dma,Ema,Fma,Gma,Hma,MZ,Ima,Jma,Kma,PZ,Lma,Mma,Nma,UZ,VZ,Oma,Pma,Qma,d_,Rma,Sma,Tma,g_,Uma,Vma,Wma,Xma;GZ=function(){};$CLJS.HZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(GZ,a):$CLJS.$a(GZ,a)};Dma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.IZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Ema=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Fma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.JZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.KZ=new $CLJS.M(null,"database-id","database-id",1883826326);Gma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.LZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Hma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);MZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Ima=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Jma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Kma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.NZ=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.OZ=new $CLJS.M(null,"dataset","dataset",1159262238);PZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Lma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.QZ=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.RZ=new $CLJS.M(null,"definition","definition",-1198729982);Mma=new $CLJS.M(null,"state","state",-1988618099);$CLJS.SZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Nma=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.TZ=new $CLJS.M("source","joins","source/joins",1225821486);UZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
VZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.WZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.XZ=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);Oma=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.YZ=new $CLJS.M(null,"selected?","selected?",-742502788);
Pma=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Qma=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.ZZ=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.$Z=new $CLJS.M("source","card","source/card",-139977973);$CLJS.a_=new $CLJS.M(null,"active","active",1895962068);$CLJS.b_=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.c_=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);d_=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Rma=new $CLJS.M(null,"details","details",1956795411);$CLJS.e_=new $CLJS.M("source","fields","source/fields",-649667981);Sma=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.f_=new $CLJS.M("source","native","source/native",-1444604147);
Tma=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);g_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.h_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Uma=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);Vma=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
Wma=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);Xma=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.i_=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.j_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(UZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Cs,$CLJS.$Z,$CLJS.f_,$CLJS.JZ,$CLJS.LZ,$CLJS.e_,$CLJS.qL,$CLJS.kL,$CLJS.TZ,$CLJS.QZ,$CLJS.SZ],null));$CLJS.X(d_,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,Xma],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,$CLJS.dL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.DE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eL,$CLJS.oL],null)],null));
$CLJS.X(MZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,Uma],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,$CLJS.dL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.DE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,$CLJS.mi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,$CLJS.mi],null)],null)],null));
$CLJS.X($CLJS.NZ,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.kk,new $CLJS.h(null,1,[$CLJS.Bt,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.WK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.ak],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CB,$CLJS.ME],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.oL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yE,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Es,$CLJS.ak],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hi,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.ME],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hL,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.oL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XZ,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.oL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.i_,
new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.DE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.DE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YK,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.h_,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.fL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IZ,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.DE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WZ,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ak,new $CLJS.h(null,2,[$CLJS.bo,1,$CLJS.lk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YZ,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,
[Oma,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,d_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wma,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MZ],null)],null)],null)],null));
$CLJS.X(g_,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZZ,$CLJS.DE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Lma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[Kma,$CLJS.DE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CB,$CLJS.ME],null)],null)],null)],null)],null)],null));
$CLJS.X(PZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.a_,$CLJS.Ss],null),new $CLJS.P(null,2,5,$CLJS.Q,[Mma,$CLJS.DE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZZ,$CLJS.DE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RZ,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,g_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VK,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,
$CLJS.DE],null)],null)],null));
$CLJS.X(Sma,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.kk,new $CLJS.h(null,1,[$CLJS.Bt,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.pL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,$CLJS.fL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.DE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KZ,$CLJS.gL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.b_,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.kk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.c_,
new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,$CLJS.kk],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OZ,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iL,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.aL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fma,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,PZ],null)],null)],null)],null));
$CLJS.X(Pma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.kk,new $CLJS.h(null,1,[$CLJS.Bt,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.$K],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,$CLJS.ZK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.DE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iL,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.kk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Vt,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.DE],null)],null)],null));
$CLJS.X(Qma,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.kk,new $CLJS.h(null,1,[$CLJS.Bt,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.bL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,$CLJS.XK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.DE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iL,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.kk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Vt,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.DE],null)],null)],null));
$CLJS.X(Tma,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kk,new $CLJS.h(null,1,[$CLJS.Bt,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.cL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.DE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yE,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.DE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tl,new $CLJS.h(null,
1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.DE],null)],null)],null));
$CLJS.X(Ema,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.kk,new $CLJS.h(null,1,[$CLJS.Bt,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.mL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,$CLJS.gL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jma,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.kk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rma,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.kk],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.j_,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.Zi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,$CLJS.Zi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Nma,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jL,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.kk],null)],null)],null));
$CLJS.X(VZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.Bt,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.HZ},Gma,$CLJS.Ig([$CLJS.Yi,$CLJS.T,$CLJS.rk,$CLJS.wi,$CLJS.Vi,$CLJS.pj,$CLJS.Bk,$CLJS.vi,$CLJS.mk,$CLJS.qk],[Dma,Vma,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dr],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.HZ)?$CLJS.HZ.H:null]))],null));
$CLJS.X(Ima,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kk,new $CLJS.h(null,1,[$CLJS.Bt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VZ],null)],null)],null)],null));