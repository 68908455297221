import React, { useEffect, useState } from 'react';
import { SessionApi } from "metabase/services";

export const ForgotPassword = (): JSX.Element => {
  const [error, setError] = useState<string | null>(null);

  const handleOktaLogin = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      if (!code || !state) {
        setError("Отсутствуют необходимые параметры для аутентификации через Okta");
        return;
      }

      await SessionApi.createWithOktaAuth({ code, state });
      window.location.href = 'http://178.213.115.64:3000/auth/login';
    } catch (err) {
      console.error('Ошибка при входе через Okta', err);
      setError('Произошла ошибка при попытке входа через Okta');
    }
  };

  useEffect(() => {
    handleOktaLogin();
  }, []);

  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20%', fontSize: '20px', fontFamily: 'Lato, sans-serif' }}>
        <p>{error}</p>
        <button 
          onClick={() => window.location.href = 'http://178.213.115.64:3000/auth/login'}
          style={{
            backgroundColor: '#05a0e3', 
            color: 'white',
            padding: '10px 20px',
            borderRadius: '6px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
            fontFamily: 'Lato, sans-serif'
          }}
        >
          Вернуться на страницу входа
        </button>
      </div>
    );
  }

  return null;
};
