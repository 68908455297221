import { useMemo } from "react";
import { jt, t } from "ttag";
import _ from "underscore";
import MetabaseSettings from "metabase/lib/settings";
import ExternalLink from "metabase/core/components/ExternalLink";
import { FormProvider } from "metabase/forms";
import FormInput from "metabase/core/components/FormInput";
import FormSubmitButton from "metabase/core/components/FormSubmitButton";
import FormErrorMessage from "metabase/core/components/FormErrorMessage";
import Breadcrumbs from "metabase/components/Breadcrumbs";
import type { SettingDefinition, Settings } from "metabase-types/api";
import { GOOGLE_SCHEMA } from "../../constants";
import {
  GoogleForm,
  GoogleFormCaption,
  GoogleFormHeader,
} from "./GoogleAuthForm.styled";

const ENABLED_KEY = "okta-auth-enabled";
const CLIENT_ID_KEY = "okta-auth-client-id";
const SECRET_KEY = "okta-secret";
const ISSUER = "okta-issuer";

const BREADCRUMBS = [
  [t`Authentication`, "/admin/settings/authentication"],
  [t`Okta Sign-In`],
];

export interface GoogleAuthFormProps {
  elements?: SettingDefinition[];
  settingValues?: Partial<Settings>;
  isEnabled: boolean;
  isSsoEnabled: boolean;
  onSubmit: (settingValues: Partial<Settings>) => void;
}

const GoogleAuthForm = ({
  elements = [],
  settingValues = {},
  isEnabled,
  isSsoEnabled,
  onSubmit,
}: GoogleAuthFormProps): JSX.Element => {
  const settings = useMemo(() => {
    return _.indexBy(elements, "key");
  }, [elements]);

  const initialValues = useMemo(() => {
    const values = GOOGLE_SCHEMA.cast(settingValues, { stripUnknown: true });
    return { ...values, [ENABLED_KEY]: true };
  }, [settingValues]);

  return (
    <FormProvider
      initialValues={initialValues}
      enableReinitialize
      validationSchema={GOOGLE_SCHEMA}
      validationContext={settings}
      onSubmit={onSubmit}
    >
      {({ dirty }) => (
        <GoogleForm disabled={!dirty}>
          <Breadcrumbs crumbs={BREADCRUMBS} />
          <GoogleFormHeader>{t`Sign in with Okta`}</GoogleFormHeader>
          <GoogleFormCaption>
            {t`Add login via Okta`}
          </GoogleFormCaption>
          <FormInput
            name={CLIENT_ID_KEY}
            title={t`Client ID`}
            placeholder={t`Client ID`}
            {...getFormFieldProps(settings[CLIENT_ID_KEY])}
          />
          <FormInput
            name={SECRET_KEY}
            title={t`Secret Key`}
            placeholder={
              isSsoEnabled
                ? "Secret Key"
                : "Secret Key"
            }
          />
          <FormInput
            name={ISSUER}
            title={t`Issuer`}
            placeholder={t`https://dev-1234567.okta.com/oauth2/default`}
            nullable
            {...getFormFieldProps(settings[ISSUER])}
          />
          <FormSubmitButton
            title={isEnabled ? t`Save changes` : t`Save and enable`}
            primary
            disabled={!dirty}
          />
          <FormErrorMessage />
        </GoogleForm>
      )}
    </FormProvider>
  );
};

const getFormFieldProps = (setting?: SettingDefinition) => {
  if (setting?.is_env_setting) {
    return { placeholder: t`Using ${setting.env_name}`, readOnly: true };
  }
};

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default GoogleAuthForm;
