var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var gX,Zka,iX,kX,lX,$ka,nX,oX,fX,pX,ala,bla,cla,dla,sX,tX,vX,wX,xX,yX,zX,AX,hX,fla,gla,DX,hla,EX,ila,GX,jla,kla,lla,mla,nla,cX;$CLJS.dX=function(a,b){if("string"===typeof b)return cX(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.eX=function(a,b,c){var d=$CLJS.im(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
gX=function(a){var b=fX;return $CLJS.QA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};Zka=function(a){var b=hX;return $CLJS.QA(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};iX=function(a){if($CLJS.md(a))return a;throw $CLJS.ii("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Ji,a],null));};
$CLJS.jX=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Uk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};kX=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
lX=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
$ka=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.Zk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.Zk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
nX=function(a,b){for(;;)switch(b=$CLJS.ll.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),mX.h?mX.h(a):mX.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(lX,a),b))){var c=a;b=$ka(a,b);a=c}else{c=$CLJS.hf.g(mX,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};oX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.KA($CLJS.ge,$CLJS.Za)($CLJS.A(a)):b:b};
fX=function(a){return $CLJS.n($CLJS.KA($CLJS.ge,$CLJS.Za)(a))?$CLJS.zh.h($CLJS.OA(kX(a).toLowerCase(),/_/,"-")):a};pX=function(a,b){var c=oX(b);return $CLJS.n(c)?(b=fX($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};ala=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.di,$CLJS.Td,$CLJS.oj,fX,$CLJS.aO,fX],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=qX.g?qX.g(b,rX):qX.call(null,b,rX);return d.call(c,b,a)})};
bla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=fX(c);var d=ala(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.oj.h(a),$CLJS.NF)&&$CLJS.Va($CLJS.aO.h(a))?$CLJS.R.j(a,$CLJS.aO,$CLJS.MG):a};cla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=kX(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(bla(b),$CLJS.T,c)],null)}),a)};
dla=function(a){a=qX.h?qX.h(a):qX.call(null,a);return hX.h?hX.h(a):hX.call(null,a)};sX=function(a){return $CLJS.eX($CLJS.eX($CLJS.fb(function(b,c){return $CLJS.eX(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.qD,$CLJS.Aea,$CLJS.OO,ela,$CLJS.kA,$CLJS.ti],null)),$CLJS.qC,dla),$CLJS.cO,$CLJS.GV)};tX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,a,null],null):a};vX=function(a){return uX.h(tX(a))};
wX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return mX($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(uX,b)))};xX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,vX(b)],null),$CLJS.hf.g(uX,c))};yX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,vX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
zX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,vX(a)],null)};AX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,uX.h(a)],null)};
hX=function(a){return BX(function(b){if($CLJS.xd(b))return Zka(b);if($CLJS.Va(oX(b)))return b;try{return uX.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.UA($CLJS.Ny);if($CLJS.n($CLJS.TA("metabase.mbql.normalize",d))){var e=$CLJS.VE("Invalid clause:");e instanceof Error?$CLJS.SA("metabase.mbql.normalize",d,$CLJS.Lw.l($CLJS.H([b])),e):$CLJS.SA("metabase.mbql.normalize",d,$CLJS.Lw.l($CLJS.H([e,b])),null)}throw $CLJS.hi($CLJS.tE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.DV(c)])),new $CLJS.h(null,
1,[$CLJS.kS,b],null),c);}throw f;}},a)};fla=function(a){return $CLJS.Df($CLJS.fm(hX,CX(a)))};gla=function(a){for(;;)if($CLJS.n(oX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Uk.g(tX,a))};DX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.NF);return $CLJS.n(b)?$CLJS.Vk.j(a,$CLJS.NF,uX):a};
hla=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,DX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,DX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};EX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.RQ);return $CLJS.n(b)?$CLJS.Vk.j(a,$CLJS.RQ,hla):a};ila=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.uQ);b=$CLJS.Va(a)?FX.h?FX.h(b):FX.call(null,b):b;return $CLJS.n(a)?EX(b):b};GX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
jla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.QF);var c=$CLJS.J.g(b,$CLJS.FN),d=$CLJS.J.g(b,$CLJS.bP),e=$CLJS.J.g(b,$CLJS.uQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.bP);var l=$CLJS.gM($CLJS.Kk.g(f,$CLJS.bP),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QF,$CLJS.bP],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Vk.j(l,$CLJS.QF,FX):l,t=$CLJS.n(c)?$CLJS.Vk.j(m,$CLJS.FN,$CLJS.Ve($CLJS.Uk,hX)):m,u=$CLJS.n(e)?$CLJS.Vk.j(t,$CLJS.uQ,EX):t;return hX(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.hi($CLJS.tE("Error canonicalizing query: {0}",
$CLJS.H([$CLJS.DV(m)])),new $CLJS.h(null,1,[$CLJS.QF,a],null),m);throw v;}};
kla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.QF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.hG);c=$CLJS.J.g(c,$CLJS.cF);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.CC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.tG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,m,$CLJS.Kk.g(t,$CLJS.zG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.LV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.HX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QF,$CLJS.cF],null),$CLJS.Sk.g($CLJS.Df,$CLJS.Ve($CLJS.Tk,b)))):a};
lla=function(a){try{return kla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.hi($CLJS.VE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.QF,a],null),b);}throw c;}};
mla=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=IX.g?IX.g(C,K):IX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return IX.g?IX.g(z,C):IX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};nla=function(a,b){a=$CLJS.Uk.g(function(c){var d=$CLJS.be.g(b,JX);return IX.g?IX.g(c,d):IX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
cX=function cX(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),cX.g?cX.g(a,k):cX.call(null,a,k)):null},null,null))};
$CLJS.HX=function HX(a){switch(arguments.length){case 3:return HX.j(arguments[0],arguments[1],arguments[2]);case 4:return HX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return HX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return HX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return HX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.HX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.HX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.HX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.HX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.HX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.HX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.HX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.HX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.HX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.HX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.HX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.HX.A=6;
var BX=function BX(a,b){return $CLJS.EV($CLJS.Ve(BX,a),$CLJS.Td,a.h?a.h(b):a.call(null,b))},mX=function mX(a){for(;;){if($CLJS.xd(a))return $CLJS.Lt(a,mX);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Pl(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return nX($CLJS.Fs,
$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hs,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return nX($CLJS.Ms,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hs,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return nX($CLJS.Ms,t);case "or":return nX($CLJS.Fs,t);default:return $CLJS.Uk.g(mX,a)}}else return a}},ola=new $CLJS.M(null,"value_field","value_field",
-980977878),KX=new $CLJS.M(null,"ascending","ascending",-988350486),LX=new $CLJS.M(null,"named","named",-422393479),MX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),NX=new $CLJS.M(null,"descending","descending",-24766135),OX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),pla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),PX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),QX=new $CLJS.M(null,"viz-settings","viz-settings",
256055379),qla=new $CLJS.M(null,"rows","rows",850049680),rla=new $CLJS.M(null,"special-fn","special-fn",1290649344),JX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),rX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),sla=new $CLJS.M(null,"label_field","label_field",-1573182765),tla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),ula=new $CLJS.M(null,"joined-field","joined-field",-2048778268),ela=new $CLJS.M(null,"visibility_type","visibility_type",
-508434247);var RX,vla=$CLJS.Xe($CLJS.N),wla=$CLJS.Xe($CLJS.N),xla=$CLJS.Xe($CLJS.N),yla=$CLJS.Xe($CLJS.N),zla=$CLJS.J.j($CLJS.N,$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));RX=new $CLJS.ei($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Sk.g(fX,$CLJS.A),zla,vla,wla,xla,yla);RX.m(null,$CLJS.iG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iG,b],null);return null!=a?$CLJS.be.g(b,a):b});
RX.m(null,$CLJS.zB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,a instanceof $CLJS.M?kX(a):a],null)});RX.m(null,$CLJS.NV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(RX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NV,qX.g?qX.g(b,rX):qX.call(null,b,rX),fX(c)],null)});
RX.m(null,$CLJS.tG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=qX.g?qX.g(a,rX):qX.call(null,a,rX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,b,function(){var d=$CLJS.n($CLJS.CB.h(c))?$CLJS.Vk.j(c,$CLJS.CB,$CLJS.zh):c;d=$CLJS.n($CLJS.zG.h(c))?$CLJS.Vk.j(d,$CLJS.zG,$CLJS.zh):d;return $CLJS.n($CLJS.FR.h(c))?$CLJS.Vk.j(d,$CLJS.FR,function(e){return $CLJS.n($CLJS.OE.h(e))?$CLJS.Vk.j(e,$CLJS.OE,$CLJS.zh):e}):d}()],null)});
RX.m(null,PX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[PX,b instanceof $CLJS.M?kX(b):b,$CLJS.zh.h(a)],null)});RX.m(null,OX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[OX,qX.g?qX.g(b,rX):qX.call(null,b,rX),$CLJS.st,fX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[OX,qX.g?qX.g(b,rX):qX.call(null,b,rX),fX(c)],null)});
RX.m(null,$CLJS.FG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(RX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.FG,b,c,d],null)),qX.g?qX.g(a,rX):qX.call(null,a,rX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.FG,qX.g?qX.g(b,rX):qX.call(null,b,rX),$CLJS.Fd(c)?c:fX(c),fX(d)],null)});
RX.m(null,$CLJS.jJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jJ,b,fX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jJ,$CLJS.dw],null)});RX.m(null,$CLJS.hJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hJ,b,fX(a)],null)});
RX.m(null,$CLJS.rJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rJ,qX.g?qX.g(b,rX):qX.call(null,b,rX),c,fX(a)],null)});RX.m(null,$CLJS.xJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xJ,qX.g?qX.g(b,rX):qX.call(null,b,rX),c,fX(a)],null)});
RX.m(null,$CLJS.pJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pJ,qX.g?qX.g(b,rX):qX.call(null,b,rX),fX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pJ,qX.g?qX.g(b,rX):qX.call(null,b,rX)],null)});
RX.m(null,$CLJS.vJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vJ,qX.g?qX.g(b,rX):qX.call(null,b,rX),fX(c),fX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vJ,qX.g?qX.g(b,rX):qX.call(null,b,rX),fX(c)],null)});
RX.m(null,$CLJS.gJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gJ,qX.g?qX.g(b,rX):qX.call(null,b,rX),qX.g?qX.g(c,rX):qX.call(null,c,rX),fX(a)],null)});RX.m(null,$CLJS.Fj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fj,b,a],null)});
RX.m(null,$CLJS.di,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[fX(a)],null),$CLJS.hf.h(function(c){return qX.g?qX.g(c,rX):qX.call(null,c,rX)}),b)});
var SX=function SX(a){if($CLJS.n($CLJS.KA($CLJS.ge,$CLJS.Za)(a))){var c=fX(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.bo,null,$CLJS.ks,null,$CLJS.IG,null,$CLJS.CG,null,$CLJS.ut,null,$CLJS.uG,null,$CLJS.NG,null,$CLJS.OG,null,$CLJS.ZF,null,$CLJS.eG,null,$CLJS.js,null,$CLJS.$F,null,$CLJS.HG,null,$CLJS.AG,null,$CLJS.lk,null,$CLJS.by,null,$CLJS.nG,null,$CLJS.GG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(oX(a))?(a=$CLJS.A(a),SX.h?SX.h(a):SX.call(null,
a)):null},Ala=new $CLJS.h(null,8,[$CLJS.oj,fX,$CLJS.uQ,function(a){a=gX(a);return $CLJS.y($CLJS.RQ.h(a))?$CLJS.Vk.j(a,$CLJS.RQ,cla):a},$CLJS.QF,new $CLJS.h(null,6,[$CLJS.iG,function TX(a){if($CLJS.n($CLJS.KA($CLJS.ge,$CLJS.Za)(a)))return fX(a);if($CLJS.n(pX(LX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[LX,TX.h?TX.h(a):TX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(oX(a))?SX($CLJS.hd(a)):null)?$CLJS.Uk.g(TX,a):qX.g?qX.g(a,
rX):qX.call(null,a,rX)},$CLJS.jQ,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[kX(u),qX.g?qX.g(t,rX):qX.call(null,t,rX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[kX(l),qX.g?qX.g(f,rX):qX.call(null,f,rX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.$R,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(oX(t))?RX.h(t):$CLJS.ce(RX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(oX(l))?RX.h(l):$CLJS.ce(RX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.TQ,function(a){a=gX(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.uQ);if($CLJS.n(b))return a=$CLJS.KV(a,new $CLJS.h(null,1,[$CLJS.uQ,$CLJS.QF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.uQ],null),a=qX.g?qX.g(a,b):qX.call(null,a,b),$CLJS.KV(a,new $CLJS.h(null,1,[$CLJS.QF,$CLJS.uQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.QF],null);return qX.g?qX.g(a,b):qX.call(null,a,b)},$CLJS.bP,new $CLJS.h(null,1,[JX,sX],null),$CLJS.ON,new $CLJS.h(null,1,[JX,function(a){a=qX.g?qX.g(a,$CLJS.QF):qX.call(null,a,$CLJS.QF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.OE),d=$CLJS.J.g(b,$CLJS.cF);a=$CLJS.J.g(b,$CLJS.gF);b=$CLJS.n(c)?$CLJS.Vk.j(b,$CLJS.OE,fX):b;d=$CLJS.n($CLJS.KA($CLJS.ge,$CLJS.Za)(d))?$CLJS.Vk.j(b,$CLJS.cF,fX):b;return $CLJS.n(a)?$CLJS.Vk.j(d,$CLJS.gF,kX):d}],null)],null),$CLJS.Ry,
new $CLJS.h(null,1,[$CLJS.zO,$CLJS.Td],null),$CLJS.FN,new $CLJS.h(null,1,[JX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.oj),d=$CLJS.J.g(b,$CLJS.CJ),e=$CLJS.J.g(b,$CLJS.aj);a=$CLJS.J.g(b,MX);b=$CLJS.n(e)?$CLJS.Vk.j(b,$CLJS.aj,kX):b;c=$CLJS.n(c)?$CLJS.Vk.j(b,$CLJS.oj,fX):b;d=$CLJS.n(d)?$CLJS.Vk.j(c,$CLJS.CJ,function(f){return qX.g?qX.g(f,rX):qX.call(null,f,rX)}):c;d=$CLJS.n(a)?$CLJS.HX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[MX,sla],null),function(f){return qX.g?qX.g(f,rX):qX.call(null,f,rX)}):d;return $CLJS.n(a)?
$CLJS.HX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[MX,ola],null),function(f){return qX.g?qX.g(f,rX):qX.call(null,f,rX)}):d}],null),$CLJS.AN,function(a){return null==a?null:fX(a)},$CLJS.bP,new $CLJS.h(null,1,[JX,sX],null),QX,fX],null),qX=function qX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return qX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
qX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.LA(Ala,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=fX(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,qX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=fX(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,qX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(oX(a))?RX.h(a):$CLJS.wd(a)?$CLJS.Uk.g(function(e){return qX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),JX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.hi($CLJS.tE("Error normalizing form: {0}",$CLJS.H([$CLJS.DV(d)])),new $CLJS.h(null,3,[$CLJS.iA,a,$CLJS.sl,c,rla,b],null),d);}throw e;}};qX.A=1;qX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var uX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(oX(f))?$CLJS.A(f):null},e,a,b,c,d)}();uX.m(null,$CLJS.di,function(a){return a});
uX.m(null,$CLJS.tG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(pX($CLJS.tG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return uX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,c,$CLJS.Fe($CLJS.jl.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,b,$CLJS.Fe(a)],null)});
uX.m(null,$CLJS.iG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iG,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,b,a],null)});uX.m(null,$CLJS.eL,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(oX(a))?uX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,a,null],null)});
uX.m(null,PX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,b,new $CLJS.h(null,1,[$CLJS.CB,a],null)],null)});uX.m(null,tla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=vX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=vX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,a,$CLJS.R.j(c,$CLJS.PN,b)],null)});
uX.m(null,ula,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=vX(a);return $CLJS.Se.v($CLJS.SV,a,$CLJS.R,$CLJS.H([$CLJS.EP,b]))});
uX.m(null,OX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=vX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.CB);$CLJS.Va(d)||$CLJS.WS.g(d,b)?a=$CLJS.Se.v($CLJS.SV,a,$CLJS.R,$CLJS.H([$CLJS.zG,b])):(c=$CLJS.UA($CLJS.Py),$CLJS.n($CLJS.TA("metabase.mbql.util",c))&&(b=$CLJS.tE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.SA("metabase.mbql.util",c,$CLJS.Lw(),b):$CLJS.SA("metabase.mbql.util",c,$CLJS.Lw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),uX.h(new $CLJS.P(null,3,5,$CLJS.Q,[OX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
uX.m(null,$CLJS.NV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=vX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,b,$CLJS.R.j(e,$CLJS.FR,$CLJS.jl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.OE,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var UX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ms,$CLJS.Fs,$CLJS.Hs],null)),VX=null,WX=0,XX=0;;)if(XX<WX){var YX=VX.X(null,XX);uX.m(null,YX,function(){return function(a){return wX(a)}}(UX,VX,WX,XX,YX));XX+=1}else{var ZX=$CLJS.y(UX);if(ZX){var $X=ZX;if($CLJS.Ad($X)){var aY=$CLJS.lc($X),Bla=$CLJS.mc($X),Cla=aY,Dla=$CLJS.D(aY);UX=Bla;VX=Cla;WX=Dla}else{var bY=$CLJS.A($X);uX.m(null,bY,function(){return function(a){return wX(a)}}(UX,VX,WX,XX,bY,$X,ZX));UX=$CLJS.B($X);VX=null;WX=0}XX=0}else break}
uX.m(null,$CLJS.bG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,vX(a),vX(b)],null),c)});uX.m(null,$CLJS.FG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=vX(a);a=$CLJS.n(lX($CLJS.tG,a))?$CLJS.SV.l(c,$CLJS.Kk,$CLJS.H([$CLJS.zG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FG,a],null),b)});
for(var cY=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.kG,$CLJS.EG,$CLJS.sG,$CLJS.lG,$CLJS.hs,$CLJS.KG,$CLJS.es,$CLJS.gs,$CLJS.as,$CLJS.cs,$CLJS.XF,$CLJS.dG,$CLJS.VF,$CLJS.fG,$CLJS.WF],null)),dY=null,eY=0,fY=0;;)if(fY<eY){var gY=dY.X(null,fY);uX.m(null,gY,function(){return function(a){return xX(a)}}(cY,dY,eY,fY,gY));fY+=1}else{var hY=$CLJS.y(cY);if(hY){var iY=hY;if($CLJS.Ad(iY)){var jY=$CLJS.lc(iY),Ela=$CLJS.mc(iY),Fla=jY,Gla=$CLJS.D(jY);cY=Ela;dY=Fla;eY=Gla}else{var kY=$CLJS.A(iY);uX.m(null,kY,
function(){return function(a){return xX(a)}}(cY,dY,eY,fY,kY,iY,hY));cY=$CLJS.B(iY);dY=null;eY=0}fY=0}else break}uX.m(null,qla,function(){return null});uX.m(null,$CLJS.FM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FM,uX.h(b),a],null)});
uX.m(null,LX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=uX.h;var e=$CLJS.Q;b=uX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,pla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.yE,a],null);return c.call(uX,new $CLJS.P(null,3,5,e,[$CLJS.FM,b,a],null))});
for(var lY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.by,$CLJS.uG],null)),mY=null,nY=0,oY=0;;)if(oY<nY){var pY=mY.X(null,oY);uX.m(null,pY,function(){return function(a){return yX(a)}}(lY,mY,nY,oY,pY));oY+=1}else{var qY=$CLJS.y(lY);if(qY){var rY=qY;if($CLJS.Ad(rY)){var sY=$CLJS.lc(rY),Hla=$CLJS.mc(rY),Ila=sY,Jla=$CLJS.D(sY);lY=Hla;mY=Ila;nY=Jla}else{var tY=$CLJS.A(rY);uX.m(null,tY,function(){return function(a){return yX(a)}}(lY,mY,nY,oY,tY,rY,qY));lY=$CLJS.B(rY);mY=null;nY=0}oY=0}else break}
for(var uY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.nG,$CLJS.PG,$CLJS.ZF,$CLJS.IG,$CLJS.GG,$CLJS.bo,$CLJS.lk,$CLJS.HG,$CLJS.$F],null)),vY=null,wY=0,xY=0;;)if(xY<wY){var yY=vY.X(null,xY);uX.m(null,yY,function(){return function(a){return zX(a)}}(uY,vY,wY,xY,yY));xY+=1}else{var zY=$CLJS.y(uY);if(zY){var AY=zY;if($CLJS.Ad(AY)){var BY=$CLJS.lc(AY),Kla=$CLJS.mc(AY),Lla=BY,Mla=$CLJS.D(BY);uY=Kla;vY=Lla;wY=Mla}else{var CY=$CLJS.A(AY);uX.m(null,CY,function(){return function(a){return zX(a)}}(uY,vY,wY,xY,
CY,AY,zY));uY=$CLJS.B(AY);vY=null;wY=0}xY=0}else break}uX.m(null,$CLJS.eG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,vX(b),a],null)});
for(var DY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AG,$CLJS.CG],null)),EY=null,FY=0,GY=0;;)if(GY<FY){var HY=EY.X(null,GY);uX.m(null,HY,function(){return function(a){return AX(a)}}(DY,EY,FY,GY,HY));GY+=1}else{var IY=$CLJS.y(DY);if(IY){var JY=IY;if($CLJS.Ad(JY)){var KY=$CLJS.lc(JY),Nla=$CLJS.mc(JY),Ola=KY,Pla=$CLJS.D(KY);DY=Nla;EY=Ola;FY=Pla}else{var LY=$CLJS.A(JY);uX.m(null,LY,function(){return function(a){return AX(a)}}(DY,EY,FY,GY,LY,JY,IY));DY=$CLJS.B(JY);EY=null;FY=0}GY=0}else break}
uX.m(null,$CLJS.NG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NG,uX.h(b),uX.h(a)],null)});
uX.m(null,$CLJS.cG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(uX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cG,b],null)),qX.l(a,$CLJS.H([rX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cG,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[uX.h(v),uX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[uX.h(m),uX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
uX.m(null,$CLJS.MF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MF,uX.h(a),$CLJS.E.g(0,b)?1:uX.h(b)],null),$CLJS.hf.g(uX,c))});
var CX=function CX(a){return function f(d,e){try{if($CLJS.n(function(){var x=iX($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=iX($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.Zk.j(e,
0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.rE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.ks,null,$CLJS.ut,null,$CLJS.OG,null,$CLJS.js,null,LX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(SX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.sE(CX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;
}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&1<=$CLJS.D(e))try{var u=$CLJS.Zk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=
G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;}else throw d;else throw x;}}($CLJS.Cf,a)},MY=function MY(a){return function f(d,e){try{var k=iX($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.qG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.jG))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,KX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=
S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,NX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,KX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,NX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof
Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.qG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qG,vX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.jG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,vX(t)],null);throw $CLJS.Y;}catch(Ma){if(Ma instanceof Error){t=Ma;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Ma;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;
}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.Zk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.Zk.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.ll.h($CLJS.hf.g(MY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof
Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.jX(f,d,e);throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.Cf,a)},FX=$CLJS.Sk.g(hX,function(a){var b=GX($CLJS.iG.h(a))?$CLJS.Vk.j(a,$CLJS.iG,fla):a;b=GX($CLJS.hG.h(a))?$CLJS.Vk.j(b,$CLJS.hG,gla):b;b=GX($CLJS.cF.h(a))?$CLJS.Vk.j(b,$CLJS.cF,$CLJS.Ve($CLJS.Uk,tX)):b;b=GX($CLJS.$R.h(a))?$CLJS.Vk.j(b,$CLJS.$R,MY):b;return GX($CLJS.TQ.h(a))?
$CLJS.Vk.j(b,$CLJS.TQ,ila):b}),Qla=new $CLJS.h(null,3,[$CLJS.uQ,$CLJS.Td,$CLJS.QF,new $CLJS.h(null,2,[$CLJS.TQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.uQ);if($CLJS.n(b))return a=$CLJS.KV(a,new $CLJS.h(null,1,[$CLJS.uQ,$CLJS.QF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.uQ],null),a=IX.g?IX.g(a,b):IX.call(null,a,b),$CLJS.KV(a,new $CLJS.h(null,1,[$CLJS.QF,$CLJS.uQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.QF],null);return IX.g?IX.g(a,b):IX.call(null,a,b)},$CLJS.ON,new $CLJS.h(null,1,
[JX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.QF],null);return IX.g?IX.g(a,b):IX.call(null,a,b)}],null)],null),QX,$CLJS.Td],null),IX=function IX(a){switch(arguments.length){case 1:return IX.h(arguments[0]);case 2:return IX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};IX.h=function(a){return IX.g(a,$CLJS.Cf)};
IX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.LA(Qla,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?mla(a,b):$CLJS.wd(a)?nla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.hi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.iA,a,$CLJS.sl,b],null),d);throw d;}};IX.A=2;
$CLJS.NY=function(){var a=$CLJS.Sk.l(IX,lla,jla,$CLJS.H([qX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.hi($CLJS.tE("Error normalizing query: {0}",$CLJS.H([$CLJS.DV(c)])),new $CLJS.h(null,1,[$CLJS.QF,b],null),c);}throw d;}}}();$CLJS.OY=function OY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.NY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Jt(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=OY.g?OY.g(f,b):OY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};