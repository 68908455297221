var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.$J=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.aK=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.bK=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var cK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ms,$CLJS.Fs],null)),dK=null,eK=0,fK=0;;)if(fK<eK){var Vga=dK.X(null,fK);$CLJS.KF(Vga,$CLJS.H([$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.bo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)],null)],null)]));fK+=1}else{var gK=$CLJS.y(cK);if(gK){var hK=gK;if($CLJS.Ad(hK)){var iK=$CLJS.lc(hK),Wga=$CLJS.mc(hK),
Xga=iK,Yga=$CLJS.D(iK);cK=Wga;dK=Xga;eK=Yga}else{var Zga=$CLJS.A(hK);$CLJS.KF(Zga,$CLJS.H([$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.bo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)],null)],null)]));cK=$CLJS.B(hK);dK=null;eK=0}fK=0}else break}$CLJS.IF($CLJS.Hs,$CLJS.H([$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)]));
for(var jK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.KG],null)),kK=null,lK=0,mK=0;;)if(mK<lK){var $ga=kK.X(null,mK);$CLJS.KF($ga,$CLJS.H([$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.bo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)],null)],null)]));mK+=1}else{var nK=$CLJS.y(jK);if(nK){var oK=nK;if($CLJS.Ad(oK)){var pK=$CLJS.lc(oK),aha=$CLJS.mc(oK),
bha=pK,cha=$CLJS.D(pK);jK=aha;kK=bha;lK=cha}else{var dha=$CLJS.A(oK);$CLJS.KF(dha,$CLJS.H([$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.bo,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)],null)],null)]));jK=$CLJS.B(oK);kK=null;lK=0}mK=0}else break}
for(var qK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.es,$CLJS.gs,$CLJS.as,$CLJS.cs],null)),rK=null,sK=0,tK=0;;)if(tK<sK){var eha=rK.X(null,tK);$CLJS.IF(eha,$CLJS.H([$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)]));tK+=1}else{var uK=$CLJS.y(qK);if(uK){var vK=uK;if($CLJS.Ad(vK)){var wK=$CLJS.lc(vK),fha=$CLJS.mc(vK),gha=wK,hha=$CLJS.D(wK);qK=fha;rK=gha;sK=hha}else{var iha=$CLJS.A(vK);$CLJS.IF(iha,$CLJS.H([$CLJS.ut,$CLJS.Mj,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)]));qK=$CLJS.B(vK);rK=null;sK=0}tK=0}else break}$CLJS.IF($CLJS.WF,$CLJS.H([$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)]));
$CLJS.IF($CLJS.bG,$CLJS.H([$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)]));
for(var xK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VF,$CLJS.fG],null)),yK=null,zK=0,AK=0;;)if(AK<zK){var jha=yK.X(null,AK);$CLJS.IF(jha,$CLJS.H([$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)]));AK+=1}else{var BK=$CLJS.y(xK);if(BK){var CK=BK;if($CLJS.Ad(CK)){var DK=$CLJS.lc(CK),kha=$CLJS.mc(CK),lha=DK,mha=$CLJS.D(DK);xK=kha;yK=lha;zK=mha}else{var nha=$CLJS.A(CK);$CLJS.IF(nha,$CLJS.H([$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)]));xK=$CLJS.B(CK);
yK=null;zK=0}AK=0}else break}
for(var EK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,$CLJS.dG],null)),FK=null,GK=0,HK=0;;)if(HK<GK){var oha=FK.X(null,HK);$CLJS.IF(oha,$CLJS.H([$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)]));HK+=1}else{var IK=$CLJS.y(EK);if(IK){var JK=IK;if($CLJS.Ad(JK)){var KK=$CLJS.lc(JK),pha=$CLJS.mc(JK),qha=KK,rha=$CLJS.D(KK);EK=pha;FK=qha;GK=rha}else{var sha=$CLJS.A(JK);$CLJS.IF(sha,$CLJS.H([$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qF],null)]));EK=$CLJS.B(JK);
FK=null;GK=0}HK=0}else break}
for(var LK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.Ss],null)],null),MK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kG,$CLJS.EG,$CLJS.sG,$CLJS.lG],null)),NK=null,OK=0,PK=0;;)if(PK<OK){var QK=NK.X(null,PK);$CLJS.TG.v(QK,$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,QK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.At,$CLJS.IE,LK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.rF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null));PK+=1}else{var RK=$CLJS.y(MK);if(RK){var SK=RK;if($CLJS.Ad(SK)){var TK=$CLJS.lc(SK),tha=$CLJS.mc(SK),uha=TK,vha=$CLJS.D(TK);MK=tha;NK=uha;OK=vha}else{var UK=$CLJS.A(SK);$CLJS.TG.v(UK,$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,UK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.At,$CLJS.IE,LK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.rF],null)],null));MK=$CLJS.B(SK);NK=null;OK=0}PK=0}else break}
$CLJS.TG.v($CLJS.FG,$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.FG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.At,$CLJS.IE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.Ss],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Cs,$CLJS.dw,$CLJS.fy,$CLJS.aG],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.uF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nH],null)],null));$CLJS.TG.v($CLJS.SF,$CLJS.ut,$CLJS.Mj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.SF],null),$CLJS.IE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fs,$CLJS.vE,$CLJS.DE],null)],null));
$CLJS.X($CLJS.$J,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.aK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.Cs,$CLJS.hs,$CLJS.KG,$CLJS.bG,$CLJS.WF,$CLJS.es,$CLJS.as,$CLJS.gs,$CLJS.cs,$CLJS.VF,$CLJS.fG,$CLJS.XF,$CLJS.dG,$CLJS.sG,$CLJS.lG,$CLJS.kG,$CLJS.EG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bK,$CLJS.Zi],null)],null));