var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Zma,$_,$ma,b0,c0,d0,e0,y0,cna,bna,dna,ana,E0,q0,G0,ena,s0;Zma=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};$_=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Hd($CLJS.Xda,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
$ma=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.EB,!0],null)),c=$CLJS.J.g(b,$CLJS.Wda),d=$CLJS.J.g(b,$CLJS.EB);return $CLJS.y(a)?(b=$CLJS.HV(function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$_(x,$CLJS.Va(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($_(u,
$CLJS.Va(d)),k($CLJS.Lc(l)))}return null}},null,null)}(Zma($CLJS.iB(a)))}()),$CLJS.n(c)?$CLJS.HV($CLJS.af(c,b)):b):null};$CLJS.a0=function(a){return $CLJS.pP.h($CLJS.A($CLJS.xL.h(a)))};b0=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=b0[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=b0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.table",a);}return a};
c0=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=c0[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=c0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.card",a);}return a};d0=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=d0[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=d0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.metric",a);}return a};
e0=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=e0[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=e0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.fields",a);}return a};$CLJS.f0=function(a){return $CLJS.HZ(a)?a:$CLJS.uE.h(a)};$CLJS.g0=function(a,b){return b0($CLJS.f0(a),b)};$CLJS.h0=function(a,b){return e0($CLJS.f0(a),b)};$CLJS.i0=function(a,b){return c0($CLJS.f0(a),b)};$CLJS.j0=function(a,b){return d0($CLJS.f0(a),b)};
$CLJS.k0=function(a,b,c){if($CLJS.Xa(b)||$CLJS.xd(b)){$CLJS.n(b.__mbcache)||(b.__mbcache=$CLJS.Xe($CLJS.N));var d=b.__mbcache;if($CLJS.n(d)){var e=$CLJS.J.g($CLJS.q(d),a);if($CLJS.n(e))return e;b=c.h?c.h(b):c.call(null,b);$CLJS.Oh.v(d,$CLJS.R,a,b);return b}}return c.h?c.h(b):c.call(null,b)};
y0=function(a,b,c){var d=$CLJS.l0.j(a,b,c);return $CLJS.jl.l($CLJS.H([$CLJS.hm(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,$CLJS.yE,$CLJS.Wj],null)),function(){var e=$CLJS.m0.v(a,b,c,$CLJS.n0);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.o0,e],null):null}(),function(){var e=$CLJS.KA($CLJS.Hi,$CLJS.CB)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Hi,e],null):null}(),function(){var e=$CLJS.iL.h(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.ZW,$CLJS.p0.j(a,b,$CLJS.Fd(e)?$CLJS.g0(a,e):"string"===typeof e?
$CLJS.i0(a,$CLJS.RW(e)):null)],null):null}(),function(){var e=$CLJS.YK.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[q0,$CLJS.E.g(e,$CLJS.JZ),$CLJS.r0,$CLJS.E.g(e,$CLJS.TZ),s0,$CLJS.E.g(e,$CLJS.QZ),$CLJS.t0,$CLJS.E.g(e,$CLJS.SZ),ana,$CLJS.E.g(e,$CLJS.qL),bna,$CLJS.E.g(e,$CLJS.kL)],null):null}(),function(){var e=$CLJS.YZ.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.u0,e],null)}(),$CLJS.hm(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.v0,$CLJS.w0,$CLJS.x0],null))]))};
$CLJS.z0=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.x0=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.A0=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.t0=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.v0=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.B0=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);
$CLJS.u0=new $CLJS.M(null,"selected","selected",574897764);cna=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);bna=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);dna=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.C0=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);
$CLJS.D0=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);ana=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);E0=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.F0=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);q0=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);
G0=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);$CLJS.H0=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.r0=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.I0=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.w0=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);ena=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.o0=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.J0=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.n0=new $CLJS.M(null,"long","long",-171452093);$CLJS.K0=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.L0=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);s0=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.M0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.pC(l)},e,a,b,c,d)}();
$CLJS.N0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.pC(l)},e,a,b,c,d)}();
$CLJS.m0=function(){function a(f,k,l,m){var t=$CLJS.yE.h($CLJS.RE(l));if($CLJS.n(t))return t;try{return $CLJS.M0.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.hi($CLJS.tE("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([l])),$CLJS.DV(k)])),new $CLJS.h(null,2,[$CLJS.QF,f,$CLJS.LE,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.di):e.call(null,f,k,l,$CLJS.di)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.O0=function(){function a(d,e,f){var k=$CLJS.T.h($CLJS.RE(f));if($CLJS.n(k))return k;try{return $CLJS.N0.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.hi($CLJS.tE("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f])),$CLJS.DV(k)])),new $CLJS.h(null,3,[$CLJS.LE,f,$CLJS.QF,d,$CLJS.NL,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.M0.m(null,$CLJS.di,function(a,b,c){a=$CLJS.UA($CLJS.Py);$CLJS.n($CLJS.TA("metabase.lib.metadata.calculation",a))&&$CLJS.SA("metabase.lib.metadata.calculation",a,$CLJS.FW("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.Mh.l($CLJS.H([c])),dna,$CLJS.pC(c)])),null);return $CLJS.zd(c)&&$CLJS.A(c)instanceof $CLJS.M?$CLJS.gh($CLJS.A(c)):$CLJS.Mh.l($CLJS.H([c]))});
$CLJS.N0.m(null,$CLJS.di,function(a,b,c){return $ma($CLJS.OA($CLJS.m0.j(a,b,c),/[\(\)]/,""))});$CLJS.P0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.fna=function(){function a(d,e,f){return $CLJS.P0.j(d,e,$CLJS.zh.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Q0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.pC(l)},e,a,b,c,d)}();
$CLJS.R0=function(){function a(d,e,f){var k=$CLJS.RE(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.zG),m=$CLJS.Hi.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Hd($CLJS.CH,l):l)?$CLJS.yj:null;if($CLJS.n(l))return l;k=$CLJS.CB.h(k);if($CLJS.n(k))return k;d=$CLJS.Q0.j(d,e,f);return $CLJS.DC(d,$CLJS.Cj)?d:$CLJS.Cj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Q0.m(null,$CLJS.di,function(a,b,c){return $CLJS.YE(c)});$CLJS.Q0.m(null,$CLJS.nF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.R0.j(a,b,c)});$CLJS.Q0.m(null,$CLJS.eJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.YE(c):$CLJS.R0.j(a,b,d)});
$CLJS.S0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.pC(l)},e,a,b,c,d)}();
$CLJS.S0.m(null,$CLJS.di,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.iu,$CLJS.WK,$CLJS.CB,$CLJS.R0.j(a,b,c),$CLJS.T,$CLJS.O0.j(a,b,c),$CLJS.yE,$CLJS.m0.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.hi($CLJS.tE("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([$CLJS.pC(c)])),$CLJS.DV(d)])),new $CLJS.h(null,3,[$CLJS.QF,a,$CLJS.NL,b,$CLJS.LE,c],null),d);}throw e;}});
$CLJS.l0=function(){function a(e,f,k){return $CLJS.S0.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.T0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.pC(l)},e,a,b,c,d)}();
$CLJS.X(E0,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yE,$CLJS.ak],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.o0,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.ak],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZW,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,E0],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[q0,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.Ss],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.r0,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.Ss],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[s0,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.Ss],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.t0,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.Ss],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[G0,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Es,$CLJS.Ss],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[ena,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.Ss],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[cna,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.Ss],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.ak],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XG,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.ak],null),
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vt,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.ak],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.K0,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.ak],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.I0,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.u0,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.Ss],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.A0,
new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,$CLJS.qG,$CLJS.jG],null)],null)],null));
$CLJS.p0=function(){function a(d,e,f){return $CLJS.k0($CLJS.zh.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.T0.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.hi($CLJS.tE("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.pC(k),$CLJS.DV(l)])),new $CLJS.h(null,3,[$CLJS.QF,d,$CLJS.NL,e,$CLJS.LE,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.T0.m(null,$CLJS.di,function(a,b,c){return y0(a,b,c)});$CLJS.T0.m(null,$CLJS.cL,function(a,b,c){return $CLJS.jl.l($CLJS.H([y0(a,b,c),new $CLJS.h(null,1,[G0,$CLJS.E.g($CLJS.a0(a),$CLJS.aj.h(c))],null)]))});
$CLJS.U0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.pC(l)},e,a,b,c,d)}();$CLJS.U0.m(null,$CLJS.FB,function(){return $CLJS.Cf});
$CLJS.V0=function(){function a(f,k,l,m){m=$CLJS.jl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.H0,$CLJS.TW()],null),m]));return $CLJS.U0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.QW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.W0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.pC(l)},e,a,b,c,d)}();$CLJS.W0.m(null,$CLJS.FB,function(){return $CLJS.Cf});
$CLJS.W0.m(null,$CLJS.di,function(a,b,c,d){return $CLJS.U0.v(a,b,c,d)});
$CLJS.X0=function(){function a(f,k,l,m){m=$CLJS.jl.l($CLJS.H([$CLJS.jl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.H0,$CLJS.TW()],null),new $CLJS.h(null,4,[$CLJS.F0,!0,$CLJS.L0,!0,$CLJS.B0,!0,$CLJS.J0,!0],null)])),m]));return $CLJS.W0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.QW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();module.exports={display_name:$CLJS.m0};