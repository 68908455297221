import React, { useState, useEffect } from 'react';
import { SessionApi } from "metabase/services";
import MetabaseSettings from "metabase/lib/settings";

export const OktaCallbackHandler = (): JSX.Element => {

  const [error, setError] = useState<string | null>(null);

  let oktaDomain = MetabaseSettings.get("okta-domain");
  console.log("Текущий домен Okta:", oktaDomain); // Логирование домена Okta

  const callbackSuffix = "*/callback";
  if (oktaDomain.endsWith(callbackSuffix)) {
    console.log("Обрезаем суффикс callback из домена Okta"); // Логирование обрезки суффикса
    oktaDomain = oktaDomain.slice(0, -callbackSuffix.length);
    console.log("Домен Okta после обрезки суффикса:", oktaDomain); // Логирование домена после обрезки
  } else {
    console.log("Домен Okta:", oktaDomain); // Логирование домена после обрезки
  }

  const handleOktaLogin = async () => {
    console.log("Начало процедуры входа через Okta"); // Логирование начала входа

    try {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      console.log("Полученные параметры:", { code, state }); // Логирование полученных параметров

      if (!code || !state) {
        console.log("Ошибка: отсутствуют необходимые параметры для аутентификации через Okta"); // Логирование отсутствия параметров
        setError("Отсутствуют необходимые параметры для аутентификации через Okta");
        return;
      }

      await SessionApi.createWithOktaAuth({ code, state });
      console.log("Успешная аутентификация через Okta, переадресация..."); // Логирование успешной аутентификации
      if (oktaDomain.endsWith("callback")) {
        oktaDomain = oktaDomain.slice(0, -"callback".length);
      }      
      window.location.href = oktaDomain;
    } catch (err) {
      console.error('Ошибка при входе через Okta', err); // Логирование ошибки входа
      setError('Произошла ошибка при попытке входа через Okta');
    }
  };

  useEffect(() => {
    console.log("Запуск эффекта для handleOktaLogin"); // Логирование запуска useEffect
    handleOktaLogin();
  }, []);

  if (error) {
    if (oktaDomain.endsWith("callback")) {
      oktaDomain = oktaDomain.slice(0, -"callback".length);
    }      
    console.log("Ошибка:", error); // Логирование наличия ошибки
    return (
      <div style={{ textAlign: 'center', marginTop: '20%', fontSize: '20px', fontFamily: 'Lato, sans-serif' }}>
        <p>{error}</p>
        <button 
          onClick={() => window.location.href = oktaDomain}
          style={{
            backgroundColor: '#05a0e3', 
            color: 'white',
            padding: '10px 20px',
            borderRadius: '6px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
            fontFamily: 'Lato, sans-serif'
          }}
        >
          Вернуться на страницу входа
        </button>
      </div>
    );
  }

  return null;
};
