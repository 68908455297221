var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var Era,Fra,V7,W7,Gra;Era=function(a){if("string"===typeof a)return $CLJS.nh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.nh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.mm(a));};$CLJS.T7=function(a){return $CLJS.Xa(a)?$CLJS.Xz(a,$CLJS.H([$CLJS.Bi,!0])):a};
Fra=function(a,b,c){var d=Era(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.JA(d))/Math.log(10)));return $CLJS.Re(function(f){f*=e;return f>=d?f:null},$CLJS.$pa)};
$CLJS.U7=function(a,b){var c=$CLJS.o7(a);if($CLJS.n(c)){var d=$CLJS.OE.h(c);d=d instanceof $CLJS.M?d.T:null;switch(d){case "num-bins":return a=$CLJS.LA(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cO,$CLJS.oj,$CLJS.Qj],null)),$CLJS.n(a)?(d=$CLJS.O(a),a=$CLJS.J.g(d,$CLJS.bo),d=$CLJS.J.g(d,$CLJS.lk),c=$CLJS.O(c),c=$CLJS.J.g(c,$CLJS.wE),c=Fra(a,d,c),new $CLJS.h(null,3,[$CLJS.NE,c,$CLJS.PL,b,$CLJS.UL,b+c],null)):null;case "bin-width":return c=$CLJS.O(c),c=$CLJS.J.g(c,$CLJS.NE),new $CLJS.h(null,3,[$CLJS.NE,c,
$CLJS.PL,b,$CLJS.UL,b+c],null);case "default":return null;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}else return null};V7=function(a){var b=$CLJS.qA.h($CLJS.G1($CLJS.f0(a)));b=b.h?b.h($CLJS.S7):b.call(null,$CLJS.S7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.td($CLJS.L4.g(b,-1))&&$CLJS.td($CLJS.Z5.g(b,-1))){if(b=$CLJS.Vk.j(b,$CLJS.xL,$CLJS.sd),!$CLJS.y($CLJS.xL.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
W7=function(a,b){var c=V7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.Kk.l(b,$CLJS.UW,$CLJS.H([$CLJS.$W]));var d=$CLJS.V0,e=d.j,f=a;var k=a;var l=$CLJS.m2(k,-1);k=$CLJS.n(l)?$CLJS.QW(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.x2.v(a,-2,$CLJS.BW(b),d);if($CLJS.n(b))a=$CLJS.Vk.j(a,$CLJS.xL,$CLJS.sd);else return null}};
Gra=function(a,b,c){var d=function(){if($CLJS.n($CLJS.o7(b))){var e=$CLJS.U7(b,c);if($CLJS.n(e)){var f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.PL);f=$CLJS.J.g(f,$CLJS.UL);var k=$CLJS.z5(b,null);e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.l2($CLJS.cs,new $CLJS.P(null,2,5,$CLJS.Q,[k,e],null)),$CLJS.l2($CLJS.es,new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null))],null)}else e=null}else e=null;return $CLJS.n(e)?e:new $CLJS.P(null,1,5,$CLJS.Q,[(0,$CLJS.E3)(b,c)],null)}();return $CLJS.fb(function(e,f){return $CLJS.F3.j(e,-1,f)},
a,d)};$CLJS.f6.m(null,$CLJS.X6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.S6);b=$CLJS.J.g(b,$CLJS.ZZ);return new $CLJS.h(null,3,[$CLJS.oj,$CLJS.X6,$CLJS.S6,a,$CLJS.ZZ,b],null)});
$CLJS.e6.m(null,$CLJS.X6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){var f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.IL),l=$CLJS.J.g(f,$CLJS.QL),m=V7(c),t=$CLJS.aX.l(m,-1,$CLJS.Kk,$CLJS.H([$CLJS.iG,$CLJS.hG,$CLJS.$R,$CLJS.wS,$CLJS.cF]));d=$CLJS.fb(function(v,x){var z=$CLJS.O(x);x=$CLJS.J.g(z,$CLJS.Vi);z=$CLJS.J.g(z,$CLJS.Fj);return Gra(v,x,z)},
t,function(){return function z(x){return new $CLJS.ne(null,function(){for(var C=x;;){var G=$CLJS.y(C);if(G){var K=G;if($CLJS.Ad(K)){var S=$CLJS.lc(K),V=$CLJS.D(S),Z=$CLJS.qe(V);return function(){for(var ra=0;;)if(ra<V){var Ma=$CLJS.kd(S,ra);Ma=$CLJS.Vk.j(Ma,$CLJS.Vi,function(){return function(zb){return W7(c,zb)}}(ra,C,Ma,S,V,Z,K,G,m,t,e,f,k,l));$CLJS.Qk.g($CLJS.YK.h($CLJS.Vi.h(Ma)),$CLJS.qL)&&Z.add(Ma);ra+=1}else return!0}()?$CLJS.te($CLJS.ve(Z),z($CLJS.mc(K))):$CLJS.te($CLJS.ve(Z),null)}var ha=
$CLJS.A(K);ha=$CLJS.Vk.j(ha,$CLJS.Vi,function(){return function(ra){return W7(c,ra)}}(C,ha,K,G,m,t,e,f,k,l));if($CLJS.Qk.g($CLJS.YK.h($CLJS.Vi.h(ha)),$CLJS.qL))return $CLJS.ee(ha,z($CLJS.Lc(K)));C=$CLJS.Lc(K)}else return null}},null,null)}(l)}());var u=function(){var v=$CLJS.id(k);return $CLJS.n(v)?$CLJS.FH(function(x){return $CLJS.E.g($CLJS.I5(x),v)},$CLJS.L4.g(m,-1)):null}();return $CLJS.fb(function(v,x){return $CLJS.F3.j(v,-1,x)},d,$CLJS.n(u)?function(){var v=$CLJS.A(u);switch(v instanceof $CLJS.M?
v.T:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.id(u)],null);case "metric":return $CLJS.G3.g($CLJS.R.j($CLJS.jZ.h((0,$CLJS.NY)($CLJS.k2($CLJS.T7(new $CLJS.h(null,2,[$CLJS.oj,$CLJS.QF,$CLJS.QF,$CLJS.T7($CLJS.RZ.h($CLJS.j0(c,$CLJS.id(u))))],null)),$CLJS.oj,$CLJS.QF))),$CLJS.eQ,$CLJS.eQ.h(c)),-1);default:return null}}():null)}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,
e,f,c)};a.l=b;return a}());