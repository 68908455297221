import { SessionApi } from "metabase/services";

export const deleteSession = async () => {
  console.log("Starting the session deletion process...");

  try {
    console.log("Sending a request to delete the session via SessionApi.delete()");
    const data = await SessionApi.delete(); // ������ ��������� ������, ��� ������ .json()
    console.log("Data received from the server:", data);

    return data; // ���������� ������ ��� ���������� ���������
  } catch (error) {
    console.error("Error during session deletion:", error);
    if (error.status !== 404) {
      console.error("Problem clearing session:", error);
    }
    throw error; // ������������� ������ ��� ��������� �� ����� ������� ������
  }
};
