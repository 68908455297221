var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var sna,tna,una,vna,wna,h1;
$CLJS.e1=function(){function a(e,f){if($CLJS.Va(f))return"";e=$CLJS.JA(e);var k=$CLJS.zh.h(f);switch(k instanceof $CLJS.M?k.T:null){case "default":return $CLJS.JV("Default period","Default periods",e);case "millisecond":return $CLJS.JV("Millisecond","Milliseconds",e);case "second":return $CLJS.JV("Second","Seconds",e);case "minute":return $CLJS.JV("Minute","Minutes",e);case "hour":return $CLJS.JV("Hour","Hours",e);case "day":return $CLJS.JV("Day","Days",e);case "week":return $CLJS.JV("Week","Weeks",
e);case "month":return $CLJS.JV("Month","Months",e);case "quarter":return $CLJS.JV("Quarter","Quarters",e);case "year":return $CLJS.JV("Year","Years",e);case "minute-of-hour":return $CLJS.JV("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.JV("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.JV("Day of week","Days of week",e);case "day-of-month":return $CLJS.JV("Day of month","Days of month",e);case "day-of-year":return $CLJS.JV("Day of year","Days of year",e);case "week-of-year":return $CLJS.JV("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.JV("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.JV("Quarter of year","Quarters of year",e);default:return f=$CLJS.au($CLJS.gh(f),/-/),e=$CLJS.y(f),f=$CLJS.A(e),e=$CLJS.B(e),$CLJS.Mt(" ",$CLJS.ee($CLJS.GA(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.f1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.pC(f)},e,a,b,c,d)}();sna=$CLJS.Xe($CLJS.N);tna=$CLJS.Xe($CLJS.N);
una=$CLJS.Xe($CLJS.N);vna=$CLJS.Xe($CLJS.N);wna=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.g1=new $CLJS.ei($CLJS.Fh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.pC,wna,sna,tna,una,vna);$CLJS.g1.m(null,$CLJS.di,function(){return null});$CLJS.g1.m(null,$CLJS.oH,function(a){return $CLJS.ti.h(a)});h1=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.fH,null,$CLJS.iH,null,$CLJS.jH,null,$CLJS.gH,null],null),null);
$CLJS.xna=$CLJS.eg.j($CLJS.Cf,$CLJS.Sk.g($CLJS.Tk.h(h1),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.iu,$CLJS.oH,$CLJS.ti,a],null);return $CLJS.E.g(a,$CLJS.sk)?$CLJS.R.j(b,$CLJS.di,!0):b})),$CLJS.zH);$CLJS.yna=$CLJS.Uk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.iu,$CLJS.oH,$CLJS.ti,a],null);return $CLJS.E.g(a,$CLJS.ju)?$CLJS.R.j(b,$CLJS.di,!0):b},$CLJS.uH);
$CLJS.zna=$CLJS.eg.j($CLJS.Cf,$CLJS.Sk.g($CLJS.Tk.h(h1),$CLJS.hf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.iu,$CLJS.oH,$CLJS.ti,a],null);return $CLJS.E.g(a,$CLJS.ju)?$CLJS.R.j(b,$CLJS.di,!0):b})),$CLJS.AH);$CLJS.M0.m(null,$CLJS.oH,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.ti);return $CLJS.e1.h(a)});
$CLJS.T0.m(null,$CLJS.oH,function(a,b,c){return $CLJS.jl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.yE,$CLJS.m0.j(a,b,c),$CLJS.K0,$CLJS.hB($CLJS.g1.h(c))],null),$CLJS.hm(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,$CLJS.u0],null))]))});
$CLJS.i1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.ei($CLJS.Fh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.pC(l)},e,a,b,c,d)}();$CLJS.i1.m(null,$CLJS.di,function(){return $CLJS.bh});
$CLJS.j1=function(){function a(d,e,f){return $CLJS.i1.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();