import styled from "@emotion/styled";

export const SectionBody = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
`;

export const StyledHomeCaption = styled.div`
  a {
    color: #509EE3; /* Цвет текста ссылки синий */
    font-weight: bold; /* Жирный шрифт */
    text-decoration: none; /* Убираем подчеркивание */
    
    &:hover {
      text-decoration: underline; /* Подчеркивание при наведении */
    }
  }
`;