var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Yoa,Zoa,$oa,apa,O3,P3,Q3,R3,bpa,cpa,dpa,epa,fpa,gpa,hpa,ipa,jpa,a4,b4,kpa,lpa,mpa,$3,Z3,npa,c4;$CLJS.J3=function(a,b){$CLJS.fb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Yoa=function(a){return function(b){var c=$CLJS.$e(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.Jl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};Zoa=function(a,b){return function f(d,e){return new $CLJS.ne(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Hd(k,t))l=$CLJS.Lc(m);else{k=$CLJS.ee(l,f($CLJS.Lc(m),$CLJS.be.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.bh)};
$oa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Va($CLJS.y($CLJS.LA(c,d)))?c:$CLJS.Se.N($CLJS.HX,c,d,e,b)};$CLJS.K3=function(a,b){return $CLJS.f1.g(a,b instanceof $CLJS.M?b:$CLJS.ti.h(b))};
$CLJS.L3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.Uk.g($CLJS.BW,d);var e=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h(function(f){return $CLJS.x2.v(a,b,f,c)}),d);return $CLJS.Uk.g(function(f){return $CLJS.R.j(f,$CLJS.YZ,$CLJS.Hd(e,f))},c)}return null};$CLJS.M3=function(a){return $CLJS.E.g($CLJS.pC(a),$CLJS.sL)};apa=function(a,b){return $CLJS.n($CLJS.cF.h(a))?$CLJS.Vk.j(a,$CLJS.cF,function(c){return $CLJS.wd(c)?$CLJS.Uk.g(function(d){return $CLJS.N3.g?$CLJS.N3.g(d,b):$CLJS.N3.call(null,d,b)},c):c}):a};
O3=function(a){if($CLJS.n(a)){var b=$CLJS.lf;a:try{if($CLJS.zd(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.zd(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.he(d,$CLJS.tG))try{var e=$CLJS.F(a,3);if($CLJS.zd(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.he(f,$CLJS.tG)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Y){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.A($CLJS.y(b($CLJS.Wa,k)))}else a=null;return a};P3=function(a){if($CLJS.n(O3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};Q3=function(a){return $CLJS.n(O3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
R3=function(a,b){var c=$CLJS.N3;if($CLJS.Va(O3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Se.j(c,a,b)],null)};
bpa=function(a,b,c){return $CLJS.td($CLJS.AL.h(a))?a:$CLJS.n(b)?$oa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.AL],null),function(d){return function l(f,k){try{if($CLJS.zd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.he(m,$CLJS.tG))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.Bc===t.mf||(t.C?0:$CLJS.$a($CLJS.wb,t)):$CLJS.$a($CLJS.wb,t))try{var u=$CLJS.J.j(t,$CLJS.EP,$CLJS.OV);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.N3.g?$CLJS.N3.g(k,c):$CLJS.N3.call(null,k,c);throw $CLJS.Y;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)return $CLJS.jX(l,f,k);throw v;}throw x;}}($CLJS.Cf,d)}):$CLJS.Vk.j(a,$CLJS.AL,function(d){return $CLJS.Uk.g(function(e){return R3(e,$CLJS.H([c]))},d)})};
$CLJS.N3=function(a,b){var c=$CLJS.pC(a);switch(c instanceof $CLJS.M?c.T:null){case "field":return $CLJS.SE(a,$CLJS.QE,$CLJS.H([$CLJS.EP,b]));case "metadata/column":return $CLJS.QE(a,$CLJS.k_,b);case "mbql/join":return c=$CLJS.P1(a),bpa(apa($CLJS.QE(a,$CLJS.gF,b),b),c,b);default:return b=$CLJS.UA($CLJS.Ny),$CLJS.n($CLJS.TA("metabase.lib.join",b))&&$CLJS.SA("metabase.lib.join",b,$CLJS.Lw.l($CLJS.H(["with-join-value should not be called with",$CLJS.Mh.l($CLJS.H([a]))])),null),a}};
$CLJS.S3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.KA($CLJS.IZ,$CLJS.T)(c);a=$CLJS.Q1($CLJS.gF.h(a),$CLJS.KA($CLJS.IZ,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.IZ,e,$CLJS.H([$CLJS.WZ,b]))};cpa=function(a,b){return $CLJS.Va(b)?a:$CLJS.Uk.g(function(c){var d=Q3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.P1(d))?null:R3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.T3=function(a,b){b=cpa($CLJS.Uk.g($CLJS.CW,b),$CLJS.P1(a));return $CLJS.QE(a,$CLJS.AL,$CLJS.Fe(b))};
$CLJS.U3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.Cf)?$CLJS.pA:$CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Sk.g($CLJS.hf.h($CLJS.BW),function(){var c=$CLJS.P1(a);return $CLJS.n(c)?$CLJS.hf.h(function(d){return $CLJS.N3(d,c)}):$CLJS.Td}()),b));return $CLJS.QE(a,$CLJS.cF,b)};
dpa=function(a,b){b=$CLJS.fm(function(d){return $CLJS.x2.g(d,a)},b);var c=$CLJS.FH($CLJS.KA($CLJS.O_,$CLJS.P_),b);if($CLJS.n(c))return c;b=$CLJS.A(b);if($CLJS.n(b))return b;b=$CLJS.FH($CLJS.O_,a);if($CLJS.n(b))return b;b=$CLJS.FH($CLJS.P_,a);return $CLJS.n(b)?b:$CLJS.A(a)};epa=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.OA(a,/ id$/i,"")):null};
fpa=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Jh(function(){return $CLJS.U2(b)});var d=new $CLJS.Jh(function(){return $CLJS.U2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
gpa=function(a,b,c){var d=$CLJS.m0.g($CLJS.E.g($CLJS.iu.h(b),$CLJS.sW)?b:a,b),e=$CLJS.n(c)?epa($CLJS.m0.g(a,c)):null,f=fpa(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Va($CLJS.nh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
hpa=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.SY(l,$CLJS.tG)&&$CLJS.Ed($CLJS.x2.v(a,b,l,e)))return $CLJS.N3(l,d);throw $CLJS.Y;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Y)return $CLJS.jX(m,k,l);throw t;}throw u;}}($CLJS.Cf,c)};
ipa=function(a,b,c,d,e,f){c=hpa(a,b,c,d,f);return function t(l,m){try{if($CLJS.zd(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.SY(u,$CLJS.tG))try{var v=$CLJS.F(m,3);if($CLJS.SY(v,$CLJS.tG)){var x=$CLJS.F(m,3),z=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.P1(z),S=$CLJS.P1(x);if($CLJS.Va($CLJS.n(K)?K:S))return $CLJS.n($CLJS.x2.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.N3(z,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,z,$CLJS.N3(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var V=
$CLJS.SE(z,$CLJS.Kk,$CLJS.H([$CLJS.EP])),Z=$CLJS.SE(x,$CLJS.Kk,$CLJS.H([$CLJS.EP])),ha=null==$CLJS.x2.v(a,b,V,e);var ra=ha?$CLJS.x2.v(a,b,Z,e):ha;return $CLJS.n(ra)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,z,Z],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,V,x],null)}return m}throw $CLJS.Y;}catch(zb){if(zb instanceof Error){var Ma=zb;if(Ma===$CLJS.Y)throw $CLJS.Y;throw Ma;}throw zb;}else throw $CLJS.Y;}catch(zb){if(zb instanceof Error){Ma=zb;if(Ma===$CLJS.Y)throw $CLJS.Y;throw Ma;}throw zb;}else throw $CLJS.Y;
}catch(zb){if(zb instanceof Error){Ma=zb;if(Ma===$CLJS.Y)return $CLJS.jX(t,l,m);throw Ma;}throw zb;}}($CLJS.Cf,c)};jpa=function(a,b){var c=$CLJS.TW();$CLJS.J3(c,b);return c(a)};
$CLJS.V3=function(a,b,c){if($CLJS.Hd(c,$CLJS.gF))return c;var d=$CLJS.QW(a,b),e=$CLJS.X0.j(a,b,d),f=$CLJS.y($CLJS.lf($CLJS.Wa,function v(t,u){try{if($CLJS.zd(u)&&1<=$CLJS.D(u))try{var x=$CLJS.Zk.j(u,0,1);if($CLJS.zd(x)&&1===$CLJS.D(x))try{var z=$CLJS.F(x,0);if($CLJS.he(z,$CLJS.tG))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Y)throw $CLJS.Y;throw C;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Y)throw $CLJS.Y;
throw C;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Y)return $CLJS.LV(v,t,u);throw C;}throw G;}}($CLJS.Cf,$CLJS.AL.h(c))));f=dpa(e,f);var k=jpa(gpa(a,c,f),$CLJS.fm($CLJS.gF,$CLJS.ON.h(d))),l=$CLJS.V0.h($CLJS.K1.g(a,$CLJS.xL.h(c)));return $CLJS.N3($CLJS.Vk.j(c,$CLJS.AL,function(t){return $CLJS.Uk.g(function(u){return ipa(a,b,u,k,e,l)},t)}),k)};$CLJS.W3=function(a){return $CLJS.AL.h(a)};
$CLJS.X3=function(a){return $CLJS.jl.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.iu,$CLJS.BL,$CLJS.OE,a],null),$CLJS.E.g(a,$CLJS.lF)?new $CLJS.h(null,1,[$CLJS.di,!0],null):null]))};$CLJS.Y3=function(a,b){b=$CLJS.A($CLJS.xL.h(b));return $CLJS.n($CLJS.ZV.h(b))?$CLJS.i0(a,$CLJS.ZV.h(b)):$CLJS.n($CLJS.pP.h(b))?$CLJS.g0(a,$CLJS.pP.h(b)):null};
a4=function(a){a=$CLJS.EH(function(d){return $CLJS.P_(d)?Z3:$CLJS.O_(d)?$3:$CLJS.eu},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,Z3);var c=$CLJS.J.g(b,$3);b=$CLJS.J.g(b,$CLJS.eu);return $CLJS.gf.l(a,c,$CLJS.H([b]))};b4=function(a,b,c,d){return $CLJS.Va(c)?d:$CLJS.Uk.g(function(e){return $CLJS.n($CLJS.YZ.h(e))?$CLJS.K3(e,$CLJS.S2(c)):e},$CLJS.L3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
kpa=function(a,b,c,d){var e=new $CLJS.Jh(function(){return $CLJS.X0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.B0,!1,$CLJS.J0,!1],null))});return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.$l(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.XZ);return $CLJS.n(function(){var m=$CLJS.O_(k);return m?l:m}())?(f=$CLJS.FH(function(m){return $CLJS.E.g(l,$CLJS.aj.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,c4,f):null):null}),$CLJS.X0.j(a,b,c)))};lpa={};
mpa=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);$3=new $CLJS.M(null,"fk","fk",398442651);Z3=new $CLJS.M(null,"pk","pk",-771936732);npa=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);c4=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.M0.m(null,$CLJS.sL,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.xL);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.pP);b=$CLJS.J.g(b,$CLJS.ZV);c=$CLJS.n(c)?$CLJS.yE.h($CLJS.g0(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.i0(a,b),a=$CLJS.n(c)?$CLJS.m0.j(a,0,c):$CLJS.U1(b)):a=null;return $CLJS.n(a)?a:$CLJS.VE("Native Query")});$CLJS.T0.m(null,$CLJS.sL,function(a,b,c){a=$CLJS.m0.j(a,b,c);c=$CLJS.gF.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.yE,a],null)});
$CLJS.S0.m(null,$CLJS.sL,function(){throw $CLJS.ii("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.M0.m(null,$CLJS.BL,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.OE);a=a instanceof $CLJS.M?a.T:null;switch(a){case "left-join":return $CLJS.VE("Left outer join");case "right-join":return $CLJS.VE("Right outer join");case "inner-join":return $CLJS.VE("Inner join");case "full-join":return $CLJS.VE("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.T0.m(null,$CLJS.BL,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.OE);c=$CLJS.J.g(d,$CLJS.di);a=new $CLJS.h(null,2,[$CLJS.K0,$CLJS.hB(e),$CLJS.yE,$CLJS.m0.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.di,!0):a});
$CLJS.U0.m(null,$CLJS.sL,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.gF),k=$CLJS.J.j(e,$CLJS.cF,$CLJS.pA),l=$CLJS.J.g(e,$CLJS.xL);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.H0);if($CLJS.E.g(k,$CLJS.pA))return null;var t="undefined"!==typeof $CLJS.qM&&"undefined"!==typeof lpa&&"undefined"!==typeof $CLJS.opa&&"undefined"!==typeof $CLJS.d4?new $CLJS.Gc(function(){return $CLJS.d4},$CLJS.od(npa,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.xL,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.Hy)?$CLJS.V0.v(u,-1,$CLJS.rd(l),c):function(){return function z(x){return new $CLJS.ne(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.Ad(C)){var G=$CLJS.lc(C),K=$CLJS.D(G),S=$CLJS.qe(K);a:for(var V=0;;)if(V<K){var Z=$CLJS.kd(G,V);Z=$CLJS.SE(Z,$CLJS.Kk,$CLJS.H([$CLJS.EP]));Z=$CLJS.l0.j(u,-1,Z);S.add(Z);V+=1}else{G=!0;break a}return G?$CLJS.te($CLJS.ve(S),z($CLJS.mc(C))):$CLJS.te($CLJS.ve(S),null)}S=$CLJS.A(C);S=$CLJS.SE(S,$CLJS.Kk,$CLJS.H([$CLJS.EP]));
return $CLJS.ee($CLJS.l0.j(u,-1,S),z($CLJS.Lc(C)))}return null}},null,null)}(k)}();return $CLJS.Uk.g(function(v){v=$CLJS.R.j(v,$CLJS.i_,f);v=$CLJS.N3($CLJS.R.l(v,$CLJS.yE,$CLJS.m0.j(a,b,v),$CLJS.H([$CLJS.YK,$CLJS.TZ])),f);return $CLJS.S3(e,m,v)},c)});$CLJS.W0.m(null,$CLJS.sL,function(a,b,c,d){return $CLJS.V0.v(a,b,$CLJS.R.j(c,$CLJS.cF,$CLJS.Hy),d)});
var e4,ppa=$CLJS.Xe($CLJS.N),qpa=$CLJS.Xe($CLJS.N),rpa=$CLJS.Xe($CLJS.N),spa=$CLJS.Xe($CLJS.N),tpa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.jj,$CLJS.rC],null),$CLJS.jj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));e4=new $CLJS.ei($CLJS.Fh.g("metabase.lib.join","join-clause-method"),$CLJS.pC,tpa,ppa,qpa,rpa,spa);e4.m(null,$CLJS.sL,function(a){return a});e4.m(null,$CLJS.sW,function(a){return $CLJS.AW(new $CLJS.h(null,2,[$CLJS.iu,$CLJS.sL,$CLJS.xL,$CLJS.xL.h($CLJS.OW(a))],null))});
e4.m(null,$CLJS.fW,function(a){return $CLJS.AW(new $CLJS.h(null,2,[$CLJS.iu,$CLJS.sL,$CLJS.xL,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});e4.m(null,$CLJS.pL,function(a){return $CLJS.AW(new $CLJS.h(null,2,[$CLJS.iu,$CLJS.sL,$CLJS.xL,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.ZV,$CLJS.aj.h(a),$CLJS.iu,$CLJS.fW],null)],null)],null))});
e4.m(null,$CLJS.cL,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.k_);a=$CLJS.J.g(b,mpa);b=e4.h(new $CLJS.h(null,3,[$CLJS.iu,$CLJS.fW,$CLJS.DB,new $CLJS.h(null,1,[$CLJS.KE,$CLJS.p.h($CLJS.UE())],null),$CLJS.pP,$CLJS.aj.h(b)],null));c=$CLJS.n(c)?$CLJS.N3(b,c):b;return $CLJS.n(a)?$CLJS.U3.g?$CLJS.U3.g(c,a):$CLJS.U3.call(null,c,a):c});
$CLJS.f4=function(){function a(d,e){return $CLJS.T3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.k2(e4.h(d),$CLJS.cF,$CLJS.Hy)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.upa=function(){function a(d,e,f){var k=$CLJS.f4.h(f);f=$CLJS.td($CLJS.W3.h?$CLJS.W3.h(k):$CLJS.W3.call(null,k))?function(){var m=$CLJS.Y3.g?$CLJS.Y3.g(d,k):$CLJS.Y3.call(null,d,k);return $CLJS.g4.j?$CLJS.g4.j(d,e,m):$CLJS.g4.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.T3(k,f):k;var l=$CLJS.V3(d,e,f);return $CLJS.aX.l(d,e,$CLJS.Vk,$CLJS.H([$CLJS.ON,function(m){return $CLJS.be.g($CLJS.Df(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.h4=function(){function a(d,e){return $CLJS.Fe($CLJS.J.g($CLJS.QW(d,e),$CLJS.ON))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.vpa=function(){function a(d){d=$CLJS.G1($CLJS.f0(d));d=$CLJS.qA.h(d);return $CLJS.eg.j($CLJS.Cf,$CLJS.Sk.g($CLJS.kf($CLJS.Ve($CLJS.Hd,d)),$CLJS.hf.h($CLJS.X3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lF,$CLJS.bF,$CLJS.pF,$CLJS.sF],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.wpa=function(){function a(d,e,f,k){var l=$CLJS.M3(f)?$CLJS.P1(f):null,m=$CLJS.eg.j($CLJS.bh,$CLJS.Sk.g($CLJS.hf.h($CLJS.P1),Yoa(function(t){return $CLJS.Qk.g(t,l)})),$CLJS.h4.g(d,e));f=$CLJS.n(k)?k:$CLJS.M3(f)?P3($CLJS.A($CLJS.W3(f))):null;return a4(b4(d,e,f,$CLJS.Tk.g(function(t){t=$CLJS.P1(t);return $CLJS.n(t)?$CLJS.Hd(m,t):null},$CLJS.X0.v(d,e,$CLJS.QW(d,e),new $CLJS.h(null,1,[$CLJS.B0,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.xpa=function(){function a(d,e,f,k,l){k=$CLJS.M3(f)?$CLJS.Y3(d,f):f;var m=$CLJS.M3(f)?$CLJS.P1(f):null;f=$CLJS.n(l)?l:$CLJS.M3(f)?Q3($CLJS.A($CLJS.W3(f))):null;f=$CLJS.n(f)?$CLJS.Va(m)?$CLJS.SE(f,$CLJS.Kk,$CLJS.H([$CLJS.EP])):f:null;return a4(b4(d,e,f,$CLJS.hf.g(function(t){t=$CLJS.R.j(t,$CLJS.YK,$CLJS.TZ);return $CLJS.n(m)?$CLJS.N3(t,m):t},$CLJS.X0.v(d,e,k,new $CLJS.h(null,1,[$CLJS.B0,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.ypa=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.C2}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.C2};return b}();
$CLJS.g4=function(){function a(d,e,f){function k(u,v){u=$CLJS.Kk.g(u,$CLJS.W1);v=$CLJS.Kk.g(v,$CLJS.W1);return $CLJS.I3.l($CLJS.A2.h($CLJS.hs),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Fe(Zoa(function(x){return $CLJS.aj.h(c4.h(x))},kpa(d,e,u,v)))}var m=$CLJS.QW(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.Uk.g(function(v){return k(v,c4.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.Uk.g(function(u){return k(c4.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.zpa=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.M3(k)?P3($CLJS.A($CLJS.W3(k))):null;$CLJS.n(l)?(l=$CLJS.p0.j(e,f,l),l=$CLJS.LA(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZW,$CLJS.yE],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.PW(e,f)){l=$CLJS.h4.g(e,f);var m=$CLJS.td(l);k=m?m:$CLJS.M3(k)?$CLJS.E.g($CLJS.gF.h(k),$CLJS.gF.h($CLJS.A(l))):null;k=$CLJS.n(k)?$CLJS.a0(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.a0(e),k=$CLJS.g0(e,k),e=$CLJS.m0.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.VE("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.Apa=function(){function a(d,e,f,k){f=$CLJS.CW.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.ti.h(k);e=$CLJS.PW(d,e);l=$CLJS.j1.j(d,e,l);d=$CLJS.j1.j(d,e,m);m=null==k||$CLJS.Hd($CLJS.fh($CLJS.hf.g($CLJS.ti,l)),k);d=null==k||$CLJS.Hd($CLJS.fh($CLJS.hf.g($CLJS.ti,d)),k);f=m?$CLJS.Vk.v(f,2,$CLJS.K3,k):f;return d?$CLJS.Vk.v(f,3,$CLJS.K3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.P0.m(null,$CLJS.ON,function(a,b){var c=$CLJS.Fe($CLJS.h4.g(a,b));c=null==c?null:$CLJS.hf.g(function(d){return $CLJS.m0.j(a,b,d)},c);return null==c?null:$CLJS.Mt(" + ",c)});