var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var qfa,rfa,sfa,tfa,ufa,vfa,wfa,xfa,yfa;qfa=function(a,b){a.sort(b||$CLJS.Da)};rfa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;qfa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.dH=function(a){return $CLJS.E.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.eH=function(a,b){if($CLJS.y(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);rfa(c,$CLJS.dH(a));return $CLJS.od($CLJS.y(c),$CLJS.pd(b))}return $CLJS.Kc};$CLJS.fH=new $CLJS.M(null,"second","second",-444702010);$CLJS.gH=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.hH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.iH=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.jH=new $CLJS.M(null,"year-of-era","year-of-era",682445876);sfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
tfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.kH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.lH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.mH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);ufa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);vfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
wfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);xfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.nH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);
$CLJS.oH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.pH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);yfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var qH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Ii,$CLJS.Hj,$CLJS.Gj,$CLJS.ri,$CLJS.ok,$CLJS.Ci,$CLJS.ki,$CLJS.jH],null),rH=$CLJS.fh(qH),zfa,Afa,Bfa;$CLJS.X(wfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid date extraction unit"],null)],null),rH));var sH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ju,$CLJS.qj,$CLJS.zj,$CLJS.qi,$CLJS.ki],null),tH=$CLJS.fh(sH);
$CLJS.X(vfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid date truncation unit"],null)],null),tH));$CLJS.uH=$CLJS.eg.j($CLJS.Cf,$CLJS.ll.o(),$CLJS.gf.g(sH,qH));zfa=$CLJS.fh($CLJS.uH);$CLJS.X($CLJS.mH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid date bucketing unit"],null)],null),zfa));var vH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gH,$CLJS.kj,$CLJS.xi],null),wH=$CLJS.fh(vH);
$CLJS.X(ufa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid time extraction unit"],null)],null),wH));var xH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.iH,$CLJS.fH,$CLJS.Wi,$CLJS.sk],null),yH=$CLJS.fh(xH);$CLJS.X(yfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid time truncation unit"],null)],null),yH));$CLJS.zH=$CLJS.eg.j($CLJS.Cf,$CLJS.ll.o(),$CLJS.gf.g(xH,vH));Afa=$CLJS.fh($CLJS.zH);
$CLJS.X(tfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid time bucketing unit"],null)],null),Afa));$CLJS.AH=$CLJS.eg.j($CLJS.Cf,$CLJS.ll.o(),$CLJS.gf.l(xH,sH,$CLJS.H([vH,qH])));Bfa=$CLJS.fh($CLJS.AH);$CLJS.X($CLJS.hH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid datetime bucketing unit"],null)],null),$CLJS.AH));var Cfa=$CLJS.be.g(Bfa,$CLJS.di);
$CLJS.X($CLJS.qE,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid temporal bucketing unit"],null)],null),Cfa));$CLJS.BH=$CLJS.ku.g(tH,yH);$CLJS.X($CLJS.kH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid datetime truncation unit"],null)],null),$CLJS.BH));$CLJS.CH=$CLJS.ku.g(rH,wH);$CLJS.X(sfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid datetime extraction unit"],null)],null),$CLJS.CH));
var DH=$CLJS.be.g(tH,$CLJS.ki);$CLJS.X($CLJS.pH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid date interval unit"],null)],null),DH));$CLJS.X($CLJS.lH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid time interval unit"],null)],null),yH));var Dfa=$CLJS.ku.g(DH,yH);$CLJS.X($CLJS.nH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Bt,"Valid datetime interval unit"],null)],null),Dfa));
$CLJS.X(xfa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.oH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ti,$CLJS.qE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),$CLJS.Ss],null)],null));