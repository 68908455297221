import type { ReactNode } from "react";
import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import type { Location } from "history";
import ScrollToTop from "metabase/hoc/ScrollToTop";
import {
  Archived,
  GenericError,
  NotFound,
  Unauthorized,
} from "metabase/containers/ErrorPages";
import { UndoListing } from "metabase/containers/UndoListing";
import {
  getErrorPage,
  getIsAdminApp,
  getIsAppBarVisible,
  getIsNavBarEnabled,
} from "metabase/selectors/app";
import { setErrorPage } from "metabase/redux/app";
import { initializeIframeResizer } from "metabase/lib/dom";
import { AppBanner } from "metabase/components/AppBanner";
import AppBar from "metabase/nav/containers/AppBar";
import Navbar from "metabase/nav/containers/Navbar";
import StatusListing from "metabase/status/components/StatusListing";
import { ContentViewportContext } from "metabase/core/context/ContentViewportContext";
import type { AppErrorDescriptor, State } from "metabase-types/store";
import { AppContainer, AppContent, AppContentContainer } from "./App.styled";
import ErrorBoundary from "./ErrorBoundary";
import { useDispatch } from "metabase/lib/redux";
import { logout } from "metabase/auth/actions";

const getErrorComponent = ({ status, data, context }: AppErrorDescriptor) => {
  if (status === 403 || data?.error_code === "unauthorized") {
    return <Unauthorized />;
  }
  if (status === 404 || data?.error_code === "not-found") {
    return <NotFound />;
  }
  if (data?.error_code === "archived" && context === "dashboard") {
    return <Archived entityName="dashboard" linkTo="/dashboards/archive" />;
  }
  if (data?.error_code === "archived" && context === "query-builder") {
    return <Archived entityName="question" linkTo="/questions/archive" />;
  }
  return <GenericError details={data?.message} />;
};

interface AppStateProps {
  errorPage: AppErrorDescriptor | null;
  isAdminApp: boolean;
  bannerMessageDescriptor?: string;
  isAppBarVisible: boolean;
  isNavBarEnabled: boolean;
}

interface AppDispatchProps {
  onError: (error: unknown) => void;
}

interface AppRouterOwnProps {
  location: Location;
  children: ReactNode;
}

type AppProps = AppStateProps & AppDispatchProps & AppRouterOwnProps;

const mapStateToProps = (
  state: State,
  props: AppRouterOwnProps
): AppStateProps => ({
  errorPage: getErrorPage(state),
  isAdminApp: getIsAdminApp(state, props),
  isAppBarVisible: getIsAppBarVisible(state, props),
  isNavBarEnabled: getIsNavBarEnabled(state, props),
});

const mapDispatchToProps: AppDispatchProps = {
  onError: setErrorPage,
};

function App({
  errorPage,
  isAdminApp,
  isAppBarVisible,
  isNavBarEnabled,
  children,
  onError,
  location,
}: AppProps) {
  const [viewportElement, setViewportElement] = useState<HTMLElement | null>(null);
  const [isSessionActive, setIsSessionActive] = useState(true);
  const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();

  // Функция для обновления куки metabase.EXPIRES
  const updateSessionCookie = () => {
    const now = new Date();
    const expires = new Date(now.getTime() + 900000); // 15 минут в миллисекундах
    document.cookie = `metabase.EXPIRES=${expires.toUTCString()};path=/;expires=${expires.toUTCString()}`;
    // console.log("Session cookie updated. New expiration time:", expires.toUTCString());
    // console.log("Time:", now.toUTCString());

  };

  // Проверка существования куки metabase.EXPIRES при загрузке компонента App
  useEffect(() => {
    const cookie = document.cookie.split("; ").find(row => row.startsWith("metabase.EXPIRES"));
    if (cookie) {
      const expiresValue = cookie.split('=')[1];
      const expiresTime = new Date(expiresValue);
      if (expiresTime < new Date()) {
        console.error("Session expired. Logging out.");
        dispatch(logout());
      } else {
        updateSessionCookie();  // Обновляем куку, если она еще действительна
      }
    } else {
      console.error("Cookie metabase.EXPIRES does not exist. Logging out.");
      dispatch(logout());
    }
  }, [dispatch]);

  // Обработчик активности пользователя
  const handleUserActivity = () => {
    if (isSessionActive) {
      resetInactivityTimer();
      updateSessionCookie();
    }
  };

  // Сброс таймера неактивности пользователя
  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimerRef.current);
    inactivityTimerRef.current = setTimeout(() => {
      console.log("Session inactive. Logging out.");
      setIsSessionActive(false);
      dispatch(logout());
    }, 900000); // 15 минут в миллисекундах
  };

  // Установка слушателей для активности пользователя
  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    resetInactivityTimer(); // Установка таймера при начальной загрузке

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      clearTimeout(inactivityTimerRef.current);
    };
  }, [isSessionActive]);  // зависимости обработчика

  useEffect(() => {
    initializeIframeResizer();
  }, []);

  return (
    <ErrorBoundary onError={onError}>
      <ScrollToTop>
        <AppContainer className="spread">
          <AppBanner location={location} />
          {isAppBarVisible && <AppBar />}
          <AppContentContainer isAdminApp={isAdminApp}>
            {isNavBarEnabled && <Navbar />}
            <AppContent ref={setViewportElement}>
              <ContentViewportContext.Provider value={viewportElement ?? null}>
                {errorPage ? getErrorComponent(errorPage) : children}
              </ContentViewportContext.Provider>
            </AppContent>
            <UndoListing />
            <StatusListing />
          </AppContentContainer>
        </AppContainer>
      </ScrollToTop>
    </ErrorBoundary>
  );
}

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default connect<AppStateProps, unknown, AppRouterOwnProps, State>(
  mapStateToProps,
  mapDispatchToProps
)(App);