var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var afa,bfa,cfa,dfa,efa,ffa,gfa,ZG,hfa,ifa,jfa,kfa,lfa,mfa,nfa,ofa,aH,pfa;$CLJS.UG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);afa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.VG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);bfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
cfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.WG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.XG=new $CLJS.M(null,"column-name","column-name",551523580);dfa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.YG=new $CLJS.M(null,"display-info","display-info",-816930907);efa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
ffa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);gfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);ZG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);hfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);ifa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);jfa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
kfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);lfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);mfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);nfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.$G=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);ofa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
aH=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.bH=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);pfa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.KF($CLJS.by,$CLJS.H([$CLJS.ut,$CLJS.yj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)],null)],null)]));$CLJS.KF($CLJS.uG,$CLJS.H([$CLJS.ut,$CLJS.yj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)],null)],null)]));
$CLJS.IF($CLJS.nG,$CLJS.H([$CLJS.ut,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.IF($CLJS.ZF,$CLJS.H([$CLJS.ut,$CLJS.yj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.IF($CLJS.CG,$CLJS.H([$CLJS.ut,$CLJS.yj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));
$CLJS.IF($CLJS.lk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));$CLJS.BF($CLJS.lk,$CLJS.nF);$CLJS.IF($CLJS.HG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.BF($CLJS.HG,$CLJS.nF);$CLJS.IF($CLJS.bo,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null)]));$CLJS.BF($CLJS.bo,$CLJS.nF);
$CLJS.X(ZG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ms,new $CLJS.h(null,1,[$CLJS.Bt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ti,new $CLJS.h(null,1,[$CLJS.Bt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.IF($CLJS.eG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZG],null)]));$CLJS.BF($CLJS.eG,$CLJS.nF);
$CLJS.IF($CLJS.AG,$CLJS.H([$CLJS.ut,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.IF($CLJS.IG,$CLJS.H([$CLJS.ut,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.IF($CLJS.GG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));
$CLJS.IF($CLJS.PG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.BF($CLJS.GG,$CLJS.nF);$CLJS.BF($CLJS.PG,$CLJS.nF);$CLJS.IF($CLJS.NG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null)]));$CLJS.BF($CLJS.NG,$CLJS.nF);
$CLJS.IF($CLJS.$F,$CLJS.H([$CLJS.ut,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.X(aH,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.Fs,ofa,mfa,ffa,bfa,afa,efa,gfa,hfa,ifa,lfa,jfa,pfa,nfa,cfa,kfa,$CLJS.Gl],null));$CLJS.X($CLJS.VG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.bo,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aH],null)],null));
$CLJS.cH=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.mG,$CLJS.by,$CLJS.WG,!1,$CLJS.$G,$CLJS.OF,$CLJS.YG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Count of rows"),$CLJS.XG,$CLJS.VE("Count"),$CLJS.Vt,$CLJS.VE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.GG,$CLJS.UG,$CLJS.hk,$CLJS.WG,!0,$CLJS.$G,$CLJS.OF,$CLJS.YG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Sum of ..."),$CLJS.XG,$CLJS.VE("Sum"),$CLJS.Vt,$CLJS.VE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.nG,$CLJS.UG,$CLJS.hk,$CLJS.WG,!0,$CLJS.$G,$CLJS.OF,$CLJS.YG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Average of ..."),$CLJS.XG,$CLJS.VE("Average"),$CLJS.Vt,$CLJS.VE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.HG,$CLJS.UG,$CLJS.hk,$CLJS.WG,!0,$CLJS.$G,$CLJS.pG,$CLJS.YG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Median of ..."),$CLJS.XG,$CLJS.VE("Median"),$CLJS.Vt,$CLJS.VE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.ZF,$CLJS.UG,$CLJS.mi,$CLJS.WG,!0,$CLJS.$G,$CLJS.OF,$CLJS.YG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Number of distinct values of ..."),$CLJS.XG,$CLJS.VE("Distinct values"),$CLJS.Vt,$CLJS.VE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.PG,$CLJS.UG,$CLJS.hk,$CLJS.WG,!0,$CLJS.$G,$CLJS.OF,$CLJS.YG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Cumulative sum of ..."),
$CLJS.XG,$CLJS.VE("Sum"),$CLJS.Vt,$CLJS.VE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.mG,$CLJS.uG,$CLJS.WG,!1,$CLJS.$G,$CLJS.OF,$CLJS.YG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Cumulative count of rows"),$CLJS.XG,$CLJS.VE("Count"),$CLJS.Vt,$CLJS.VE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.IG,$CLJS.UG,$CLJS.hk,$CLJS.WG,
!0,$CLJS.$G,$CLJS.DG,$CLJS.YG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Standard deviation of ..."),$CLJS.XG,$CLJS.VE("SD"),$CLJS.Vt,$CLJS.VE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.bo,$CLJS.UG,$CLJS.$i,$CLJS.WG,!0,$CLJS.$G,$CLJS.OF,$CLJS.YG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Minimum of ..."),$CLJS.XG,$CLJS.VE("Min"),$CLJS.Vt,$CLJS.VE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.mG,$CLJS.lk,$CLJS.UG,$CLJS.$i,$CLJS.WG,!0,$CLJS.$G,$CLJS.OF,$CLJS.YG,function(){return new $CLJS.h(null,3,[$CLJS.yE,$CLJS.VE("Maximum of ..."),$CLJS.XG,$CLJS.VE("Max"),$CLJS.Vt,$CLJS.VE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(dfa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hs,$CLJS.bH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Cs],null),$CLJS.hf.h($CLJS.mG),$CLJS.cH)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UG,new $CLJS.h(null,1,[$CLJS.Ws,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Es,$CLJS.Zi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WG,$CLJS.Ss],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.$G,$CLJS.Zi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YG,$CLJS.md],null)],null));